import {SharedUser, SegmentSharing, DeletedStatus} from '~/graphql-enty/graphql';
import ShareModel from '~/affordance/sharing/ShareModel';
import {QueryStringFilters} from '~/feature/filter/types';
import {
    AdType,
    Brand,
    Campaign,
    Category,
    Industry,
    MediaOwner,
    MediaType,
    Product,
    Publisher,
    Region
} from 'bigdatr-style';
import {AdTypeInput} from 'bigdatr-style/src/adType/data/AdType';
import {BrandInput} from 'bigdatr-style/src/brand/data/Brand';
import {CategoryInput} from 'bigdatr-style/src/category/data/Category';
import {IndustryInput} from 'bigdatr-style/src/industry/data/Industry';
import {MediaOwnerInput} from 'bigdatr-style/src/mediaOwner/data/MediaOwner';
import {MediaTypeInput} from 'bigdatr-style/src/mediaType/data/MediaType';
import {ProductInput} from 'bigdatr-style/src/product/data/Product';
import {PublisherInput} from 'bigdatr-style/src/publisher/data/Publisher';
import {RegionInput} from 'bigdatr-style/src/region/data/Region';
import {CampaignInput} from 'bigdatr-style/src/campaign/data/Campaign';

export type SegmentDefinition = {
    adType?: Array<AdType>;
    brand?: Array<Brand>;
    category?: Array<Category>;
    industry?: Array<Industry>;
    mediaOwner?: Array<MediaOwner>;
    mediaType?: Array<MediaType>;
    product?: Array<Product>;
    publisher?: Array<Publisher>;
    region?: Array<Region>;
    campaign?: Array<Campaign>;
    searchQuery?: string;
    advertiserDomain?: string[];
    advertiserName?: string[];
};

export function convertSegmentToFilters(segment: SegmentDefinition): QueryStringFilters {
    const queryStringFilters: QueryStringFilters = {};

    queryStringFilters.adType = segment.adType?.map((ii) => ii.id);
    queryStringFilters.brand = segment.brand?.map((ii) => ii.id);
    queryStringFilters.category = segment.category?.map((ii) => ii.id);
    queryStringFilters.industry = segment.industry?.map((ii) => ii.id);
    queryStringFilters.mediaOwner = segment.mediaOwner?.map((ii) => ii.id);
    queryStringFilters.mediaType = segment.mediaType?.map((ii) => ii.id);
    queryStringFilters.product = segment.product?.map((ii) => ii.id);
    queryStringFilters.publisher = segment.publisher?.map((ii) => ii.id);
    queryStringFilters.region = segment.region?.map((ii) => ii.id);
    queryStringFilters.campaign = segment.campaign?.map((ii) => ii.id);
    queryStringFilters.searchQuery = segment.searchQuery;
    queryStringFilters.advertiserDomain = segment.advertiserDomain;
    queryStringFilters.advertiserName = segment.advertiserName;

    return queryStringFilters;
}

export type SegmentInput = {
    id: string;
    name: string;
    color: string;
    description?: string | null;
    definitionV2?: {
        adType?: Array<AdType> | AdTypeInput[];
        brand?: Array<Brand> | BrandInput[];
        category?: Array<Category> | CategoryInput[];
        industry?: Array<Industry> | IndustryInput[];
        mediaOwner?: Array<MediaOwner> | MediaOwnerInput[];
        mediaType?: Array<MediaType> | MediaTypeInput[];
        product?: Array<Product> | ProductInput[];
        publisher?: Array<Publisher> | PublisherInput[];
        region?: Array<Region> | RegionInput[];
        campaign?: Array<Campaign> | CampaignInput[];
        searchQuery?: string;
        advertiserDomain?: string[];
        advertiserName?: string[];
    };
    emailAlert: boolean;
    author: SharedUser;
    teamId: string;
    teamName?: string | null;
    sharing: SegmentSharing;
    status?: DeletedStatus | null;
    createdAt: string;
    updatedAt: string;
};

export default class Segment extends ShareModel {
    id: string;
    name: string;
    color: string;
    description: string | null;
    emailAlert: boolean;
    definitionV2?: SegmentDefinition;
    author: SharedUser;
    teamId: string;
    teamName: string;
    status: DeletedStatus | null;
    createdAt: string;
    updatedAt: string;
    type = 'segment' as const;

    constructor(input: SegmentInput) {
        super();
        this.id = input.id;
        this.name = input.name;
        this.color = input.color;
        this.description = input.description ?? null;
        this.emailAlert = input.emailAlert;
        this.author = input.author;
        this.teamId = input.teamId;
        this.teamName = input.teamName || 'Unknown team';
        this.sharing = input.sharing;
        this.status = input.status ?? null;
        this.createdAt = input.createdAt;
        this.updatedAt = input.updatedAt;

        this.definitionV2 = {
            adType: input.definitionV2?.adType?.map((e) => ensure(AdType)(e)),
            brand: input.definitionV2?.brand?.map((e) => ensure(Brand)(e)),
            category: input.definitionV2?.category?.map((e) => ensure(Category)(e)),
            industry: input.definitionV2?.industry?.map((e) => ensure(Industry)(e)),
            mediaOwner: input.definitionV2?.mediaOwner?.map((e) => ensure(MediaOwner)(e)),
            mediaType: input.definitionV2?.mediaType?.map((e) => ensure(MediaType)(e)),
            product: input.definitionV2?.product?.map((e) => ensure(Product)(e)),
            publisher: input.definitionV2?.publisher?.map((e) => ensure(Publisher)(e)),
            region: input.definitionV2?.region?.map((e) => ensure(Region)(e)),
            campaign: input.definitionV2?.campaign?.map((e) => ensure(Campaign)(e)),
            searchQuery: input.definitionV2?.searchQuery,
            advertiserDomain: input.definitionV2?.advertiserDomain,
            advertiserName: input.definitionV2?.advertiserName
        };
    }

    static defaultName = 'Untitled segment';

    static defaultDescription =
        'Enter a description. This can help others better understand your segment.';

    static welcomeTitle = 'Welcome to Segments!';

    static welcomeText =
        'Segments let you combine multiple filters into one, and assign custom colours.';

    static secretCreativeEmailSegmentName = 'Bigdatr Daily Creative Email';

    static to(id: string) {
        return `/segment/${id}`;
    }

    get to() {
        return Segment.to(this.id);
    }

    get label() {
        return this.name;
    }

    get deleted() {
        return this.status === 'DELETED';
    }
}

function ensure<C, A>(Clazz: {new (input: A): C}) {
    return function (input: C | A) {
        if (input instanceof Clazz) return input;
        return new Clazz(input as A);
    };
}
