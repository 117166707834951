import {graphql} from '~/graphql-react-query/';

export const ViewerQuery = graphql(`
    query ViewerQuery {
        viewer {
            id
            forecastCutoff
            forecastCutoffDigital
            maxDate
            userOnboardingInfo {
                isAustraliaOnboarded
            }
            teams {
                id
                name
                role
                products {
                    product
                    industryIds
                    industries
                    status
                    metadata
                    country
                }
                trialExpired
                hasTrialTeamName
                requestedNzTrial
                downloadCount
            }
            user {
                firstname
                id
                lastname
                name
                username
                createdAt
                registrationStatus
                sessionMetadata
            }
        }
    }
`);
