import {useQuery} from '@tanstack/react-query';
import {TeamProductStatus, User, Viewer, ViewerTeam} from 'bigdatr-style';
import {ProductType} from 'bigdatr-style/src/team/Team';
import {SupportedCountry} from '~/feature/country/CountrySwitch';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';
import {ViewerQuery} from './graphqlQueries';

export function useViewerRequest(currentTeamId: string) {
    const request = useClientMainAuthorizedFetch();
    return useQuery({
        queryKey: ['viewer', currentTeamId],
        queryFn: async () => {
            const response = await request(ViewerQuery, undefined);
            if (!response.viewer) throw new Error('Viewer not returned from api');

            const viewer = new Viewer({
                currentTeamId,
                ...response.viewer,
                teams: response.viewer.teams.map(
                    (team) =>
                        new ViewerTeam({
                            ...team,
                            products: team.products.map((product) => ({
                                ...product,
                                product: product.product as ProductType,
                                status: product.status as TeamProductStatus,
                                country: product.country as SupportedCountry
                            }))
                        })
                ),
                user: new User(response.viewer.user)
            });
            return viewer;
        }
    });
}
