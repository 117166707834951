import {safeLazyImport, BoringRoute, LazyRoute, Parse} from 'bigdatr-style';
import {OnboardFollowBrandsArgs} from './OnboardFollowBrands';

const OnboardWelcome = safeLazyImport(() => import('./OnboardWelcome'));
const OnboardFollowBrands = safeLazyImport(() => import('./OnboardFollowBrands'));

export default {
    // au and nz routes
    onboardStart: BoringRoute({
        path: '/welcome',
        component: OnboardWelcome
    }),

    // au specific routes
    onboardFollowBrands: LazyRoute<OnboardFollowBrandsArgs>({
        path: '/welcome/follow',
        parse: {
            q: Parse.query.JSON((x) => x as OnboardFollowBrandsArgs['q'])
        },
        component: OnboardFollowBrands
    })
};
