import {ClassSchema, Creative, GraphqlRequest} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import {CreativeItemV2QueryVariables, CreativeListV2QueryVariables} from '~/graphql-enty/graphql';
import {ObjectSchema} from 'react-enty';
import {ArraySchema} from 'react-enty';
import {EntitySchema} from 'react-enty';
import {Schemas} from 'bigdatr-style';

const CreativeListV2Query = EntityStrict(
    () => import('~/creative/data/CreativeListV2Query.graphql')
);
const CreativeItemV2Query = EntityStrict(
    () => import('~/creative/data/CreativeItemV2Query.graphql')
);

export type CreativeV2ApiType = {
    creativeV2: {
        creativeListV2: GraphqlRequest<
            {
                creativeV2: {
                    creativeListV2: {
                        items: Creative[];
                        totalCount: number;
                        paginationInfo: {
                            hasPreviousPage: boolean;
                            hasNextPage: boolean;
                        };
                    };
                };
            },
            CreativeListV2QueryVariables
        >;
        publicCreativeItemV2: GraphqlRequest<
            {creativeV2: {publicCreativeItemV2: Creative}},
            CreativeItemV2QueryVariables
        >;
    };
};

export const CreativeApiV2 = {
    creativeV2: {
        creativeListV2: CreativeListV2Query,
        publicCreativeItemV2: CreativeItemV2Query
    }
};

const {adType, brandList, categoryList, industryList, mediaTypeList, productList, regionList} =
    Schemas;

export const creative = new EntitySchema('creative');
export const creativeList = new ArraySchema(creative);

creative.shape = new ClassSchema(Creative, {
    adType,
    brandList,
    categoryList,
    industryList,
    mediaTypeList,
    productList,
    regionList
});

export const CreativeApiV2Schema = {
    creativeV2: new ObjectSchema({
        creativeListV2: new ObjectSchema({items: creativeList}),
        publicCreativeItemV2: creative
    })
};
