import {graphql} from '~/graphql-react-query/';

export const CreativeEmailSegmentCreate = graphql(`
    mutation CreativeEmailSegmentCreate($input: SegmentV2CreateInput!) {
        segmentV2 {
            segmentCreate(input: $input) {
                id
                name
                color
                teamId
                teamName
                description
                definitionV2
                emailAlert
                author {
                    id
                    name
                    email
                }
                sharing {
                    status
                    usersWithAccess {
                        id
                        name
                        email
                    }
                }
                createdAt
                updatedAt
                status
            }
        }
    }
`);

export const CreativeEmailSegmentUpdate = graphql(`
    mutation CreativeEmailSegmentUpdate($input: CreativeEmailSegmentUpdateInput!) {
        segmentV2 {
            creativeEmailSegmentUpdate(input: $input) {
                id
                name
                color
                teamId
                description
                definitionV2
                emailAlert
                author {
                    id
                    name
                    email
                }
                sharing {
                    status
                    usersWithAccess {
                        id
                        name
                        email
                    }
                }
                createdAt
                updatedAt
                status
            }
        }
    }
`);

export const CreativeEmailSegmentQuery = graphql(`
    query CreativeEmailSegmentQuery($input: CreativeEmailSegmentInput) {
        segmentV2 {
            creativeEmailSegment(input: $input) {
                id
                name
                color
                teamId
                description
                definitionV2
                emailAlert
                author {
                    id
                    name
                    email
                }
                sharing {
                    status
                    usersWithAccess {
                        id
                        name
                        email
                    }
                }
                createdAt
                updatedAt
                status
            }
        }
    }
`);
