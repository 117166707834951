import {TypedDocumentString} from '~/graphql-react-query/graphql';
import {useAuth} from 'bigdatr-style';

/**
 * Uses our authorized fetch to add correct headers and error handling for requests,
 * and uses the graphql codegen to type the payload and response */
export default function useClientMainAuthorizedFetch() {
    const {authorizedFetch} = useAuth();

    return async <TResult, TVariables>(
        query: TypedDocumentString<TResult, TVariables>,
        ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
    ) => {
        const response = await authorizedFetch(process.env.BIGDATR_ENTITY_API_ENDPOINT || '', {
            query,
            variables
        });

        if (response.errors) throw response.errors[0] as Error;

        return response.data as TResult;
    };
}
