import {IconLock, IconBuildings, IconPeople} from 'bigdatr-style/icon';
import React from 'react';
import {SharedUser, SharedStatus} from '~/graphql-enty/graphql';

export default class ShareModel {
    sharing: {
        status: SharedStatus;
        usersWithAccess: SharedUser[];
    };

    static shareStatusIcon(status: SharedStatus) {
        switch (status) {
            case 'private':
                return <IconLock data-testid="private-icon" />;
            case 'wholeTeam':
                return <IconBuildings data-testid="whole-team-icon" />;
            case 'specificTeamMembers':
                return <IconPeople data-testid="specific-members-icon" />;
        }
    }

    static shareStatusDescription(status: SharedStatus) {
        switch (status) {
            case 'private':
                return 'Not shared with anyone';
            case 'wholeTeam':
                return 'Shared with whole team';
            case 'specificTeamMembers':
                return 'Shared with selected team members';
        }
    }

    static shareStatusLabel(status: SharedStatus) {
        switch (status) {
            case 'private':
                return 'Only me';
            case 'wholeTeam':
                return 'Entire team';
            case 'specificTeamMembers':
                return 'Specific team members';
        }
    }

    get shareStatusDescription() {
        return ShareModel.shareStatusDescription(this.sharing.status);
    }

    get shareStatusIcon() {
        return ShareModel.shareStatusIcon(this.sharing.status);
    }
}
