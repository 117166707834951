import {GraphqlRequest, createPollingGenerator} from 'bigdatr-style';
import Endpoints from '~/app/Endpoints';
import {
    PdfCreativeProfileMutationMutationVariables,
    PdfReportCreateMutationMutationVariables
} from '~/graphql-enty/graphql';
const PdfReportCreateMutation = () => import('~/pdf/data/PdfReportCreateMutation.graphql');
const PdfCreativeProfileMutation = () => import('~/pdf/data/PdfCreativeProfileMutation.graphql');
const {EntityStrict} = Endpoints;

export type PdfApiType = {
    pdf: {
        reportDownload: GraphqlRequest<
            {status: number; downloadUrl: string},
            PdfReportCreateMutationMutationVariables & {debug?: boolean}
        >;
        creativeProfileDownload: GraphqlRequest<
            {status: number; downloadUrl: string},
            PdfCreativeProfileMutationMutationVariables & {debug?: boolean}
        >;
    };
};

export default {
    reportDownload: async function reportDownload(payload, meta) {
        const pollingData = createPollingGenerator({
            createRequest: async () => {
                const response = await EntityStrict(PdfReportCreateMutation)(payload, meta);
                return response.pdfRenderer.pdfReportCreate.downloadLink;
            },
            pollRequest: async (createResponse) => {
                const response = await fetch(createResponse);
                return {status: response.status, downloadUrl: createResponse};
            },
            pollWhile: (data: any) => data?.status === 404,
            interval: 2000
        });

        // Enty can handle generators but not async generators
        // Can side step this by flattening the whole request to one promise
        let lastPayload;
        for await (const payload of pollingData) {
            lastPayload = payload;
        }
        return lastPayload;
    },
    creativeProfileDownload: async function creativeProfileDownload(payload, meta) {
        const pollingData = createPollingGenerator({
            createRequest: async () => {
                const response = await EntityStrict(PdfCreativeProfileMutation)(payload, meta);
                return response.pdfRenderer.pdfCreative.downloadLink;
            },
            pollRequest: async (createResponse) => {
                const response = await fetch(createResponse);
                return {status: response.status, downloadUrl: createResponse};
            },
            pollWhile: (data: any) => data?.status === 404,
            interval: 2000
        });

        // Enty can handle generators but not async generators
        // Can side step this by flattening the whole request to one promise
        let lastPayload;
        for await (const payload of pollingData) {
            lastPayload = payload;
        }
        return lastPayload;
    }
};
