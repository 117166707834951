import {skipToken, useInfiniteQuery, useSuspenseQuery} from '@tanstack/react-query';
import {FilterColumnV2, FilterQueryQuery} from '~/graphql-react-query/graphql';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';
import {FilterQuery} from './graphqlQueries';
import {AdType, Brand, Campaign, Category, CreativeStatus, Industry} from 'bigdatr-style';
import {MediaOwner, MediaType, Product, Publication, Publisher, Region} from 'bigdatr-style';
import AdvertiserDomain from '~/feature/AdvertiserDomain';
import AdvertiserName from '~/feature/AdvertiserName';
import Changeset from '~/feature/Changeset';
import Segment from '~/segment/data/Segment';
import PlacementType from '~/feature/PlacementType';
import Location from '~/feature/Location';

export type FilterForFilteringFilters = {
    brand?: string[];
    category?: string[];
    product?: string[];
    mediaOwner?: string[];
    mediaType?: string[];
    industry?: string[];
    publisher?: string[];
};

export function useFilters(
    args: {
        input: {country: string; searchQuery: string; column: FilterColumnV2};
        filter?: FilterForFilteringFilters;
    },
    options?: {enabled: boolean}
) {
    const {column, country, searchQuery} = args.input;
    const {filter} = args;
    const request = useClientMainAuthorizedFetch();
    const enabled = options?.enabled ?? true;
    return useInfiniteQuery({
        queryKey: ['filter', column, searchQuery, country, filter],
        queryFn: enabled
            ? async ({pageParam}) => {
                  const response = await request(FilterQuery, {
                      input: {
                          column,
                          searchQuery: searchQuery.trim(),
                          country,
                          pageSize: 100,
                          pageNum: pageParam
                      },
                      filter: filter
                  });
                  const filters = response.filterV2?.filters;
                  if (!filters) throw new Error('Filters not returned from api');

                  const data = mapResponseToClassInstances(filters.items);
                  const hasNextPage = filters.pageInfo.hasNextPage;
                  const pageNum = filters.pageInfo.currentPage;
                  return {data, pageNum, hasNextPage};
              }
            : skipToken,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            if (lastPage.hasNextPage) return lastPage.pageNum + 1;
            return null;
        }
    });
}

export function useSuspenseFilters(args: {
    input: {country: string; searchQuery: string; column: FilterColumnV2};
    filter?: FilterForFilteringFilters;
}) {
    const {column, country, searchQuery} = args.input;
    const {filter} = args;
    const request = useClientMainAuthorizedFetch();

    return useSuspenseQuery({
        queryKey: ['filters', column, searchQuery, country, filter],
        queryFn: async () => {
            const response = await request(FilterQuery, {
                input: {
                    column,
                    searchQuery: searchQuery.trim(),
                    country,
                    pageSize: 100,
                    pageNum: 1
                },
                filter: filter
            });

            const filters = response.filterV2?.filters;
            if (!filters) throw new Error('Suspense filters not returned from api');

            return mapResponseToClassInstances(filters.items);
        }
    });
}

type Input = NonNullable<NonNullable<FilterQueryQuery['filterV2']>['filters']>['items'];
function mapResponseToClassInstances(props: Input) {
    return {
        adType: (props.adType || []).map((e) => new AdType(e)),
        advertiserDomain: (props.advertiserDomain || []).map((e) => new AdvertiserDomain(e)),
        advertiserName: (props.advertiserName || []).map((e) => new AdvertiserName(e)),
        brand: (props.brand || []).map((e) => new Brand(e)),
        campaign: (props.campaign || []).map((e) => new Campaign(e)),
        category: (props.category || []).map((e) => new Category(e)),
        changeset: (props.changeset || []).map((e) => new Changeset(e)),
        industry: (props.industry || []).map((e) => new Industry(e)),
        location: (props.location || []).map((e) => new Location(e)),
        mediaOwner: (props.mediaOwner || []).map((e) => new MediaOwner(e)),
        mediaType: (props.mediaType || []).map((e) => new MediaType(e)),
        placementType: (props.placementType || []).map((e) => new PlacementType(e)),
        product: (props.product || []).map((e) => new Product(e)),
        publication: (props.publication || []).map((e) => new Publication(e)),
        publisher: (props.publisher || []).map((e) => new Publisher(e)),
        region: (props.region || []).map((e) => new Region(e)),
        segment: (props.segment || []).map((e) => new Segment(e)),
        status: (props.status || []).map((e) => new CreativeStatus(e))
    };
}
