import {EntyRequest, GraphqlRequest, User} from 'bigdatr-style';
import type {Meta} from '~/app/Endpoints';
import Endpoints from '~/app/Endpoints';
import {
    UserListQueryQueryVariables,
    UserUpdateSessionMetadataMutationMutationVariables
} from '~/graphql-enty/graphql';
import {Schemas} from 'bigdatr-style';
import {ArraySchema, ObjectSchema} from 'react-enty';
import {reportList} from '~/report/data/ReportApi';
import Report from '~/report/data/Report';
import Segment from '~/segment/data/Segment';
import {segment} from '~/segment/data/SegmentApi';

const {EntityStrict} = Endpoints;
const userUpdate = EntityStrict(() => import('~/user/data/UserUpdateMutation.graphql'));
const userUpdateSessionMetadata = EntityStrict(
    () => import('~/user/data/UserUpdateSessionMetadataMutation.graphql')
);
const userItemQuery = EntityStrict(() => import('~/user/data/UserItemQuery.graphql'));
const userListQuery = EntityStrict(() => import('~/user/data/UserListQuery.graphql'));
const reportChangeOwnership = EntityStrict(
    () => import('~/report/data/ReportChangeOwnershipMutation.graphql')
);
const segmentChangeOwnership = EntityStrict(
    () => import('~/segment/data/SegmentChangeOwnershipMutation.graphql')
);

type ChangeOwnershipArgs = {
    reportIds: string[];
    segmentIds: string[];
    newUserId: string;
    teamId: string;
};

export default {
    userItem: userItemQuery,
    userList: async (variables: UserListQueryQueryVariables, meta: Meta) => {
        const data = await userListQuery(variables, meta);
        if (data.user?.userList) data.user.userList.id = JSON.stringify(variables);
        return data;
    },
    userUpdate: async (user: User, meta: Meta) => {
        const payload = {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone
        };
        return userUpdate({user: payload}, meta);
    },
    userUpdateSessionMetadata: async (
        user: {id: User; sessionMetadata: Record<string, string>},
        meta: Meta
    ) => {
        return await userUpdateSessionMetadata({...user}, meta);
    },
    changeOwnership: async (args: ChangeOwnershipArgs, meta: Meta) => {
        const {reportIds, segmentIds, newUserId, teamId} = args;
        const [reports, segments] = await Promise.all([
            await Promise.all(
                reportIds.map(async (id) => {
                    return reportChangeOwnership({input: {id, newUserId, newTeamId: teamId}}, meta);
                })
            ),
            await Promise.all(
                segmentIds.map(async (id) => {
                    return segmentChangeOwnership(
                        {input: {id, newUserId, newTeamId: teamId}},
                        meta
                    );
                })
            )
        ]);

        return {
            reports: reports.map((response) => response.reportV2?.reportChangeOwnership?.report),
            segments: segments.map((response) => response?.segmentV2?.segmentChangeOwnership)
        };
    }
};

export type UserApi = {
    user: {
        userList: GraphqlRequest<{user: {userList: User[]}}, UserListQueryQueryVariables>;
        changeOwnership: GraphqlRequest<
            {reports: Report[]; segments: Segment[]},
            ChangeOwnershipArgs
        >;
        userUpdate: EntyRequest<{user: {userUpdate: User}}>;
        userUpdateSessionMetadata: GraphqlRequest<
            {user: {id: User['id']; sessionMetadata: Record<string, string>}},
            UserUpdateSessionMetadataMutationMutationVariables
        >;
        userUpdateProducts: EntyRequest<{user: {userUpdate: User}}>;
        userItem: EntyRequest<{user: {user: User}}>;
    };
};

const {user, userList} = Schemas;
export const UserSchema = {
    user: new ObjectSchema({
        user,
        userUpdate: user,
        userList,
        userUpdateProducts: user,
        changeOwnership: new ObjectSchema({
            reports: reportList,
            segments: new ArraySchema(segment)
        })
    })
};
