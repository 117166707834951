/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
  Long: { input: number; output: number; }
  UUID: { input: string; output: string; }
};

export type AbsoluteDateRangeFilter = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type ActivityMutation = {
  userFolderCreate?: Maybe<ActivityUserFolder>;
  userFolderRemove?: Maybe<Scalars['Boolean']['output']>;
  userFolderUpdate?: Maybe<ActivityUserFolder>;
};


export type ActivityMutationUserFolderCreateArgs = {
  folder: ActivityUserFolderCreateInput;
};


export type ActivityMutationUserFolderRemoveArgs = {
  id: Scalars['UUID']['input'];
};


export type ActivityMutationUserFolderUpdateArgs = {
  folder: ActivityUserFolderUpdateInput;
};

export type ActivityQuery = {
  userFolderList?: Maybe<Array<Maybe<ActivityUserFolder>>>;
};

export type ActivityUserFolder = {
  activityTypes?: Maybe<Array<Maybe<ActivityUserFolderActivityType>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emailAlert?: Maybe<Scalars['Boolean']['output']>;
  emailAlertFrequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rules?: Maybe<ActivityUserFolderRules>;
  rulesOperator?: Maybe<ActivityUserFolderRulesOperatorType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityUserFolderActivityType =
  | 'newCampaigns'
  | 'newCreatives'
  | 'sovReport';

export type ActivityUserFolderBrandRule = {
  name?: Maybe<Scalars['String']['output']>;
};

export type ActivityUserFolderBrandRuleInput = {
  name: Scalars['String']['input'];
};

export type ActivityUserFolderCategoryRule = {
  industry?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ActivityUserFolderCategoryRuleInput = {
  industry: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ActivityUserFolderCreateInput = {
  activityTypes: Array<InputMaybe<ActivityUserFolderActivityType>>;
  emailAlert?: InputMaybe<Scalars['Boolean']['input']>;
  emailAlertFrequency?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rules?: InputMaybe<ActivityUserFolderRulesInput>;
  rulesOperator?: InputMaybe<ActivityUserFolderRulesOperatorType>;
};

export type ActivityUserFolderIndustryRule = {
  name?: Maybe<Scalars['String']['output']>;
};

export type ActivityUserFolderIndustryRuleInput = {
  name: Scalars['String']['input'];
};

export type ActivityUserFolderRules = {
  brand?: Maybe<Array<Maybe<ActivityUserFolderBrandRule>>>;
  category?: Maybe<Array<Maybe<ActivityUserFolderCategoryRule>>>;
  industry?: Maybe<Array<Maybe<ActivityUserFolderIndustryRule>>>;
};

export type ActivityUserFolderRulesInput = {
  brand?: InputMaybe<Array<InputMaybe<ActivityUserFolderBrandRuleInput>>>;
  category?: InputMaybe<Array<InputMaybe<ActivityUserFolderCategoryRuleInput>>>;
  industry?: InputMaybe<Array<InputMaybe<ActivityUserFolderIndustryRuleInput>>>;
};

export type ActivityUserFolderRulesOperatorType =
  | 'ALL'
  | 'ANY';

export type ActivityUserFolderUpdateInput = {
  activityTypes?: InputMaybe<Array<InputMaybe<ActivityUserFolderActivityType>>>;
  emailAlert?: InputMaybe<Scalars['Boolean']['input']>;
  emailAlertFrequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rules?: InputMaybe<ActivityUserFolderRulesInput>;
  rulesOperator?: InputMaybe<ActivityUserFolderRulesOperatorType>;
};

export type AdTypeV2 = {
  canonicalName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AddNzTrialInput = {
  formFields: Array<TrackingFormField>;
};

export type AddUserToTeamInput = {
  role: TeamMemberRole;
  teamId: Scalars['UUID']['input'];
  userId: Scalars['String']['input'];
};

export type AdvertiserDomain = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdvertiserName = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Brand = {
  key?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandIndustryListItem = {
  allowedAccess?: Maybe<Scalars['Boolean']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
};

export type BrandItemCampaign = {
  creativeList?: Maybe<BrandItemCampignCreativeList>;
  creativeTheme?: Maybe<Scalars['String']['output']>;
  firstAppeared?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastAppeared?: Maybe<Scalars['Date']['output']>;
  spend?: Maybe<Scalars['Float']['output']>;
};

export type BrandItemCampignCreativeList = {
  creativeList?: Maybe<Array<Maybe<BrandItemCreative>>>;
  summary?: Maybe<BrandItemCreativeListSummary>;
};

export type BrandItemCreative = {
  adType?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Brand>;
  categoryList?: Maybe<Array<Maybe<Category>>>;
  creative?: Maybe<Scalars['String']['output']>;
  creativeTheme?: Maybe<Scalars['String']['output']>;
  firstAppeared?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastAppeared?: Maybe<Scalars['Date']['output']>;
  mediaTypeList?: Maybe<Array<Maybe<MediaType>>>;
  spend?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type BrandItemCreativeList = {
  campaignList?: Maybe<Array<Maybe<BrandItemCampaign>>>;
  creativeList?: Maybe<Array<Maybe<BrandItemCreative>>>;
  summary?: Maybe<BrandItemCreativeListSummary>;
};

export type BrandItemCreativeListSummary = {
  activeCreatives?: Maybe<CountAndSpend>;
  adTypes?: Maybe<Array<Maybe<CountAndSpendWithName>>>;
  campaigns?: Maybe<Array<Maybe<CountAndSpendWithName>>>;
  existingCreatives?: Maybe<CountAndSpend>;
  mediaTypes?: Maybe<Array<Maybe<CountAndSpendWithName>>>;
  newCreatives?: Maybe<CountAndSpend>;
  phrase?: Maybe<Phrase>;
};

export type BrandItemSummary = {
  countActiveCampaigns?: Maybe<BrandItemSummaryValue>;
  countNewCreatives?: Maybe<BrandItemSummaryValue>;
  industryRank?: Maybe<BrandItemSummaryValue>;
  industrySpendShare?: Maybe<BrandItemSummaryValue>;
  totalSpend?: Maybe<BrandItemSummaryValue>;
};

export type BrandItemSummaryValue = {
  delta?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BrandListItem = {
  brand?: Maybe<Brand>;
  industryList?: Maybe<Array<Maybe<Industry>>>;
  primaryIndustry?: Maybe<Industry>;
};

export type BrandScreen = {
  brandIndustryList?: Maybe<Array<Maybe<BrandIndustryListItem>>>;
  brandItem?: Maybe<Brand>;
  brandItemCreativeList?: Maybe<BrandItemCreativeList>;
  brandItemSummary?: Maybe<BrandItemSummary>;
  brandList?: Maybe<Array<Maybe<BrandListItem>>>;
};


export type BrandScreenBrandIndustryListArgs = {
  brandName: Scalars['String']['input'];
};


export type BrandScreenBrandItemArgs = {
  brandName: Scalars['String']['input'];
};


export type BrandScreenBrandItemCreativeListArgs = {
  brandName: Scalars['String']['input'];
  filter: BrandSpendFilter;
};


export type BrandScreenBrandItemSummaryArgs = {
  brandName: Scalars['String']['input'];
  filter: BrandSpendFilter;
};

export type BrandSpendFilter = {
  endDate: Scalars['Date']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export type BrandV2 = {
  id: Scalars['String']['output'];
  industries?: Maybe<Array<IndustryV2>>;
  name: Scalars['String']['output'];
};

export type BrandsPaginationInfo = {
  currentPage?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type CachedStatus =
  | 'HIT'
  | 'MISS';

export type CampaignV2 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Category = {
  industry?: Maybe<Industry>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CategoryNameInput = {
  category: Scalars['String']['input'];
  industry: Scalars['String']['input'];
};

export type CategoryV2 = {
  id: Scalars['String']['output'];
  industry?: Maybe<IndustryV2>;
  name: Scalars['String']['output'];
};

export type ChangeOwnershipData = {
  report: ReportListItem;
  reportItems: Array<ShallowReportItem>;
};

export type Changeset = {
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ChangesetOrderBy = {
  field: ChangesetOrderByFields;
  order: OrderDirection;
};

export type ChangesetOrderByFields =
  | 'createdAt'
  | 'name'
  | 'updatedAt';

export type ChangesetPageInfo = {
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type ChangesetPagination = {
  count: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type ChangesetQuery = {
  search: ChangesetResult;
};


export type ChangesetQuerySearchArgs = {
  orderBy?: InputMaybe<ChangesetOrderBy>;
  pagination?: InputMaybe<ChangesetPagination>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ChangesetStatus>>;
};

export type ChangesetResult = {
  items: Array<Changeset>;
  pageInfo: ChangesetPageInfo;
};

export type ChangesetStatus =
  | 'ARCHIVED'
  | 'DRAFT'
  | 'DRAFT_PREPARE_FAILED'
  | 'DRAFT_PREPARING'
  | 'DRAFT_READY'
  | 'LIVE'
  | 'PREVIOUS';

export type ColumnFilter = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignId?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ColumnTotal = {
  column: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ComparisonPeriod = {
  absolute?: InputMaybe<AbsoluteDateRangeFilter>;
};

export type ConvertFiltersInput = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<CategoryNameInput>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ConvertFiltersOutput = {
  adType?: Maybe<Array<Scalars['String']['output']>>;
  brand?: Maybe<Array<Scalars['String']['output']>>;
  category?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Array<Scalars['String']['output']>>;
  mediaType?: Maybe<Array<Scalars['String']['output']>>;
  product?: Maybe<Array<Scalars['String']['output']>>;
  region?: Maybe<Array<Scalars['String']['output']>>;
};

export type ConvertNameToEntitiesInput = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ConvertNameToEntitiesOutput = {
  adType?: Maybe<Array<AdTypeV2>>;
  brand?: Maybe<Array<BrandV2>>;
  industry?: Maybe<Array<IndustryV2>>;
  mediaType?: Maybe<Array<MediaTypeV2>>;
  region?: Maybe<Array<RegionV2>>;
};

export type CountAndSpend = {
  count?: Maybe<Scalars['Int']['output']>;
  spend?: Maybe<Scalars['Float']['output']>;
};

export type CountAndSpendWithName = {
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  spend?: Maybe<Scalars['Float']['output']>;
};

export type CreateTeamWithInviteInput = {
  email: Scalars['String']['input'];
  inviterId: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};

export type CreativeEmailSegmentInput = {
  fullPrimitive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreativeEmailSegmentUpdateInput = {
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  emailAlert?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
};

export type CreativeItemV2 = {
  adType?: Maybe<AdTypeV2>;
  advertiserDomain?: Maybe<Scalars['String']['output']>;
  advertiserName?: Maybe<Scalars['String']['output']>;
  brandList: Array<BrandV2>;
  categoryList: Array<CategoryV2>;
  country?: Maybe<Scalars['String']['output']>;
  creative: Scalars['String']['output'];
  firstAppeared?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  industryList: Array<IndustryV2>;
  lastAppeared?: Maybe<Scalars['Date']['output']>;
  mediaOwnerList: Array<MediaOwner>;
  mediaTypeList: Array<MediaTypeV2>;
  placementTypes?: Maybe<Array<Scalars['String']['output']>>;
  productList: Array<ProductV2>;
  publicationList: Array<PublicationV2>;
  publisherList: Array<Publisher>;
  regionList: Array<PublicationV2>;
  status: CreativeStatusV2;
};

export type CreativeListDateRangeFilterV2 = {
  absolute?: InputMaybe<AbsoluteDateRangeFilter>;
  relative?: InputMaybe<CreativeListRelativeDateRangeFilter>;
};

export type CreativeListFilterInputV2 = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  advertiserDomain?: InputMaybe<Array<Scalars['String']['input']>>;
  advertiserName?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaign?: InputMaybe<Array<Scalars['ID']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAtBetween?: InputMaybe<DateRange>;
  dateRangeFilter?: InputMaybe<CreativeListDateRangeFilterV2>;
  firstAppearedBetween?: InputMaybe<DateRange>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  placementType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  publication?: InputMaybe<Array<Scalars['ID']['input']>>;
  publisher?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<CreativeStatusV2>>;
};

export type CreativeListInputV2 = {
  filters?: InputMaybe<CreativeListFilterInputV2>;
  orderBy?: InputMaybe<CreativeListOrderByV2>;
  pagination?: InputMaybe<CreativeListPaginationV2>;
};

export type CreativeListOrderByV2 = {
  field: CreativeListOrderFieldV2;
  order: OrderByOrders;
};

export type CreativeListOrderFieldV2 =
  | 'firstAppeared'
  | 'lastAppeared'
  | 'score';

export type CreativeListPageV2 = {
  items: Array<CreativeItemV2>;
  paginationInfo?: Maybe<CreativeListPaginationInfoV2>;
  totalCount: Scalars['Int']['output'];
};

export type CreativeListPaginationInfoV2 = {
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type CreativeListPaginationV2 = {
  count: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type CreativeListRelativeDateRangeFilter = {
  months: Scalars['Int']['input'];
};

export type CreativeQueryV2 = {
  creativeListV2: CreativeListPageV2;
  publicCreativeItemV2: PublicCreativeItemV2;
};


export type CreativeQueryV2CreativeListV2Args = {
  input: CreativeListInputV2;
};


export type CreativeQueryV2PublicCreativeItemV2Args = {
  creativeId: Scalars['ID']['input'];
};

export type CreativeStatusV2 =
  | 'active'
  | 'inactive'
  | 'new';

export type DashboardBrandMediaValue = {
  change?: Maybe<Scalars['Float']['output']>;
  currentPeriod?: Maybe<Array<Maybe<DashboardBrandMediaValueItem>>>;
  currentPeriodTotal?: Maybe<Scalars['Float']['output']>;
  hasAccess?: Maybe<Scalars['Boolean']['output']>;
  previousPeriod?: Maybe<Array<Maybe<DashboardBrandMediaValueItem>>>;
  previousPeriodTotal?: Maybe<Scalars['Float']['output']>;
};

export type DashboardBrandMediaValueItem = {
  month?: Maybe<Scalars['String']['output']>;
  spend?: Maybe<Scalars['Float']['output']>;
};

export type DashboardBrandsV2 = {
  brand?: Maybe<BrandV2>;
  creatives?: Maybe<Array<Maybe<CreativeItemV2>>>;
  mediaValue?: Maybe<DashboardBrandMediaValue>;
};

export type DashboardBrandsV3 = {
  brands?: Maybe<Array<DashboardBrandsV2>>;
  paginationInfo?: Maybe<BrandsPaginationInfo>;
};

export type DashboardQueryV2 = {
  followedBrands?: Maybe<Array<Maybe<DashboardBrandsV2>>>;
  topBrands?: Maybe<Array<Maybe<DashboardBrandsV2>>>;
};


export type DashboardQueryV2FollowedBrandsArgs = {
  input: DashboardQueryV2Input;
};


export type DashboardQueryV2TopBrandsArgs = {
  input: DashboardQueryV2Input;
};

export type DashboardQueryV2Input = {
  creativeCount: Scalars['Int']['input'];
  dateRangeFilter: DataTableDateRangeFilter;
};

export type DashboardQueryV3 = {
  followedBrands?: Maybe<DashboardBrandsV3>;
  topBrands?: Maybe<DashboardBrandsV3>;
};


export type DashboardQueryV3FollowedBrandsArgs = {
  input: DashboardQueryV3Input;
};


export type DashboardQueryV3TopBrandsArgs = {
  input: DashboardQueryV3Input;
};

export type DashboardQueryV3Input = {
  creativeCount: Scalars['Int']['input'];
  dateRangeFilter: DataTableDateRangeFilter;
  pagination?: InputMaybe<MediaValuePagination>;
};

export type DashboardV2 = {
  followedBrands?: Maybe<Array<Maybe<DashboardBrandsV2>>>;
  topBrands?: Maybe<Array<Maybe<DashboardBrandsV2>>>;
};

export type DataExtent = {
  from?: Maybe<Scalars['Date']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type DataTableDateRangeFilter = {
  absolute?: InputMaybe<AbsoluteDateRangeFilter>;
  relative?: InputMaybe<MediaValueRelativeDateRangeFilter>;
  truncateMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type DateRange = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type DeletedStatus =
  | 'ACTIVE'
  | 'DELETED';

export type EntitiesById = {
  adType: Array<AdTypeV2>;
  brand: Array<BrandV2>;
  campaign: Array<CampaignV2>;
  category: Array<CategoryV2>;
  changeset: Array<Changeset>;
  industry: Array<IndustryV2>;
  mediaOwner: Array<MediaOwner>;
  mediaType: Array<MediaTypeV2>;
  product: Array<ProductV2>;
  publication: Array<PublicationV2>;
  publisher?: Maybe<Array<Publisher>>;
  region: Array<RegionV2>;
  release: Array<Release>;
  segment: Array<SegmentV2>;
};

export type FilterColumn =
  | 'adType'
  | 'advertiserDomain'
  | 'advertiserName'
  | 'brand'
  | 'campaignId'
  | 'category'
  | 'industry'
  | 'location'
  | 'mediaOwner'
  | 'mediaType'
  | 'placementType'
  | 'product'
  | 'publicationId'
  | 'publisher'
  | 'region'
  | 'segment'
  | 'status';

export type FilterColumnV2 =
  | 'adType'
  | 'advertiserDomain'
  | 'advertiserName'
  | 'brand'
  | 'campaign'
  | 'category'
  | 'changeset'
  | 'industry'
  | 'location'
  | 'mediaOwner'
  | 'mediaType'
  | 'placementType'
  | 'product'
  | 'publication'
  | 'publisher'
  | 'region'
  | 'release'
  | 'segment'
  | 'status';

export type FilterForFilteringFiltersV2 = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaign?: InputMaybe<Array<Scalars['ID']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRangeFilter?: InputMaybe<CreativeListDateRangeFilterV2>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  placementType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  publication?: InputMaybe<Array<Scalars['ID']['input']>>;
  publisher?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Array<CreativeStatusV2>>;
};

export type FilterInputV2 = {
  column: FilterColumnV2;
  country?: InputMaybe<Scalars['String']['input']>;
  pageNum?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type FilterItem = {
  filter: Array<FilterItemFilter>;
  id?: Maybe<Scalars['ID']['output']>;
  label: Scalars['String']['output'];
};

export type FilterItemFilter = {
  column?: Maybe<FilterColumn>;
  value: Scalars['String']['output'];
};

export type FilterItemV2Response = {
  adType?: Maybe<Array<AdTypeV2>>;
  advertiserDomain?: Maybe<Array<AdvertiserDomain>>;
  advertiserName?: Maybe<Array<AdvertiserName>>;
  brand?: Maybe<Array<BrandV2>>;
  campaign?: Maybe<Array<CampaignV2>>;
  category?: Maybe<Array<CategoryV2>>;
  changeset?: Maybe<Array<Changeset>>;
  industry?: Maybe<Array<IndustryV2>>;
  location?: Maybe<Array<LocationFilterV2>>;
  mediaOwner?: Maybe<Array<MediaOwner>>;
  mediaType?: Maybe<Array<MediaTypeV2>>;
  placementType?: Maybe<Array<PlacementType>>;
  product?: Maybe<Array<ProductV2>>;
  publication?: Maybe<Array<PublicationV2>>;
  publisher?: Maybe<Array<Publisher>>;
  region?: Maybe<Array<RegionV2>>;
  release?: Maybe<Array<Release>>;
  segment?: Maybe<Array<SegmentV2>>;
  status?: Maybe<Array<StatusFilterV2>>;
};

export type FilterQueryV2 = {
  convertNameFiltersToIds?: Maybe<ConvertFiltersOutput>;
  convertNamesToEntities?: Maybe<ConvertNameToEntitiesOutput>;
  filters?: Maybe<FilterResultV2>;
  getEntitiesById: EntitiesById;
};


export type FilterQueryV2ConvertNameFiltersToIdsArgs = {
  input: ConvertFiltersInput;
};


export type FilterQueryV2ConvertNamesToEntitiesArgs = {
  input: ConvertNameToEntitiesInput;
};


export type FilterQueryV2FiltersArgs = {
  filter?: InputMaybe<FilterForFilteringFiltersV2>;
  input: FilterInputV2;
};


export type FilterQueryV2GetEntitiesByIdArgs = {
  input: GetEntitiesByIdInput;
};

export type FilterResultV2 = {
  column: FilterColumnV2;
  id: Scalars['ID']['output'];
  items: FilterItemV2Response;
  pageInfo: FilterTypeResultPageInfo;
};

export type FilterTypeResultPageInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type FirstNonAuSegmentCreateInput = {
  country: Scalars['String']['input'];
  definitionV2: Scalars['JSON']['input'];
  emailAlert: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type GetEntitiesByIdInput = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaign?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  changeset?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  publication?: InputMaybe<Array<Scalars['String']['input']>>;
  publisher?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  release?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GraphDimension =
  | 'brand_name'
  | 'category_name'
  | 'creative'
  | 'creative_adType'
  | 'creative_mediaType'
  | 'creative_region'
  | 'creative_theme'
  | 'industry_name'
  | 'product_name'
  | 'spot_month';

export type GraphFilter = {
  adType?: InputMaybe<GraphFilterAdType>;
  brand?: InputMaybe<GraphFilterBrand>;
  campaign?: InputMaybe<GraphFilterCampaign>;
  category?: InputMaybe<GraphFilterCategory>;
  dateRange?: InputMaybe<GraphFilterDateRange>;
  industry?: InputMaybe<GraphFilterIndustry>;
  mediaType?: InputMaybe<GraphFilterMediaType>;
  product?: InputMaybe<GraphFilterProduct>;
  region?: InputMaybe<GraphFilterRegion>;
};

export type GraphFilterAdType = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterBrand = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterCampaign = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterCategory = {
  categoryOfBrand?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterDateRange = {
  max?: InputMaybe<Scalars['Date']['input']>;
  min?: InputMaybe<Scalars['Date']['input']>;
};

export type GraphFilterIndustry = {
  industryOfBrand?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterMediaType = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterProduct = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphFilterRegion = {
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphMetric =
  | 'spot_spend';

export type GraphNode = {
  name?: InputMaybe<Scalars['String']['input']>;
  type: GraphNodeType;
};

export type GraphNodeType =
  | 'brand'
  | 'campaign'
  | 'category'
  | 'industry'
  | 'product'
  | 'region'
  | 'universe';

export type GraphResult = {
  meta?: Maybe<GraphResultMeta>;
  rows?: Maybe<Scalars['JSON']['output']>;
};

export type GraphResultMeta = {
  resultIncludesDigital?: Maybe<Scalars['Boolean']['output']>;
  resultIncludesUnattributedBrandMediaValue?: Maybe<Scalars['Boolean']['output']>;
  totalUnattributedBrandMediaValue?: Maybe<Scalars['Float']['output']>;
};

export type IncrementDownloadCountInput = {
  id: Scalars['UUID']['input'];
};

export type Industry = {
  name?: Maybe<Scalars['String']['output']>;
};

export type IndustryV2 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InputColumn =
  | 'adType'
  | 'brand'
  | 'campaignId'
  | 'category'
  | 'industry'
  | 'mediaType'
  | 'product'
  | 'region'
  | 'segment';

export type InviteUserToTeamInput = {
  inviterId: Scalars['String']['input'];
  role: TeamMemberRole;
  teamId: Scalars['UUID']['input'];
  username: Scalars['String']['input'];
};

export type JobExecutionMutationSchema = {
  executeCreativeDownload?: Maybe<JobExecutionResult>;
};


export type JobExecutionMutationSchemaExecuteCreativeDownloadArgs = {
  creativeList: Array<InputMaybe<Scalars['String']['input']>>;
  downloadFilename: Scalars['String']['input'];
};

export type JobExecutionQuerySchema = {
  executionStatus?: Maybe<JobExecutionStatus>;
};


export type JobExecutionQuerySchemaExecutionStatusArgs = {
  executionId: Scalars['UUID']['input'];
  jobName: JobName;
};

export type JobExecutionResult = {
  executionId?: Maybe<Scalars['UUID']['output']>;
};

export type JobExecutionStatus = {
  finalOutput?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type JobName =
  | 'creativeDownload';

export type LocationFilterV2 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LocationV2 = {
  name: Scalars['String']['output'];
};

export type MediaOwner = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MediaType = {
  name?: Maybe<Scalars['String']['output']>;
};

export type MediaTypeV2 = {
  canonicalName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MediaValueBreakdownColumn =
  | 'adType'
  | 'brand'
  | 'category'
  | 'industry'
  | 'mediaOwner'
  | 'mediaType'
  | 'product'
  | 'publisher'
  | 'region';

export type MediaValueColumnFilter = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaign?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  publisher?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MediaValueCsvResult = {
  csv: Scalars['String']['output'];
};

export type MediaValueDateRangeFilter = {
  absolute?: InputMaybe<AbsoluteDateRangeFilter>;
  relative?: InputMaybe<MediaValueRelativeDateRangeFilter>;
  truncateMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaValueExcludeList = {
  adType?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  campaign?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  mediaType?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  publisher?: InputMaybe<Array<Scalars['String']['input']>>;
  region?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MediaValueInputColumn =
  | 'adType'
  | 'brand'
  | 'campaign'
  | 'category'
  | 'industry'
  | 'mediaOwner'
  | 'mediaType'
  | 'product'
  | 'publisher'
  | 'region'
  | 'segment';

export type MediaValueLabels = {
  adType: Array<AdTypeV2>;
  brand: Array<BrandV2>;
  campaign: Array<CampaignV2>;
  category: Array<CategoryV2>;
  industry: Array<IndustryV2>;
  mediaOwner: Array<MediaOwner>;
  mediaType: Array<MediaTypeV2>;
  product: Array<ProductV2>;
  publication: Array<PublicationV2>;
  publisher: Array<Publisher>;
  region: Array<RegionV2>;
  segment: Array<Segment>;
};

export type MediaValueMeta = {
  isTruncated: Scalars['Boolean']['output'];
};

export type MediaValueOrderByInput = {
  column: Scalars['String']['input'];
  order: OrderByOrders;
};

export type MediaValuePageInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type MediaValuePagination = {
  orderBy?: InputMaybe<MediaValueOrderByInput>;
  pageNum?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaValueQuery = {
  csv: MediaValueCsvResult;
  growthAnalysis: MediaValueReportOutput;
  rows: MediaValueResult;
  verticalAnalysis: MediaValueReportOutput;
};


export type MediaValueQueryCsvArgs = {
  changesetId?: InputMaybe<Scalars['Int']['input']>;
  columnFilter?: InputMaybe<MediaValueColumnFilter>;
  columns?: InputMaybe<Array<MediaValueInputColumn>>;
  csvFileName?: InputMaybe<Scalars['String']['input']>;
  dateRangeFilter?: InputMaybe<MediaValueDateRangeFilter>;
  excludeList?: InputMaybe<MediaValueExcludeList>;
  includePercentage?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<MediaValuePagination>;
  releaseId?: InputMaybe<Scalars['Int']['input']>;
};


export type MediaValueQueryGrowthAnalysisArgs = {
  periodSelection: PeriodSelection;
  rows: Array<MediaValueRowFilterInput>;
};


export type MediaValueQueryRowsArgs = {
  changesetId?: InputMaybe<Scalars['Int']['input']>;
  columnFilter?: InputMaybe<MediaValueColumnFilter>;
  columns?: InputMaybe<Array<MediaValueInputColumn>>;
  dateRangeFilter?: InputMaybe<MediaValueDateRangeFilter>;
  excludeList?: InputMaybe<MediaValueExcludeList>;
  pagination?: InputMaybe<MediaValuePagination>;
  releaseId?: InputMaybe<Scalars['Int']['input']>;
};


export type MediaValueQueryVerticalAnalysisArgs = {
  breakdownColumn: MediaValueBreakdownColumn;
  dateRangeFilter: MediaValueDateRangeFilter;
  rows: Array<MediaValueRowFilterInput>;
};

export type MediaValueRelativeDateRangeFilter = {
  includeForecasted: Scalars['Boolean']['input'];
  months: Scalars['Int']['input'];
};

export type MediaValueReportOutput = {
  columnMeta: Scalars['JSON']['output'];
  rows: Scalars['JSON']['output'];
};

export type MediaValueResult = {
  columnTotals: Array<ColumnTotal>;
  grandTotal: Scalars['Float']['output'];
  labels: MediaValueLabels;
  meta?: Maybe<MediaValueMeta>;
  pageInfo: MediaValuePageInfo;
  rows: Array<MediaValueRow>;
};

export type MediaValueRow = {
  adType?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  mediaOwner?: Maybe<Scalars['String']['output']>;
  mediaType?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  publisher?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type MediaValueRowFilterInput = {
  filter: MediaValueColumnFilter;
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type MetaQuery = {
  dateExtent?: Maybe<DataExtent>;
  smiCutoff?: Maybe<Scalars['Date']['output']>;
};

export type Mutation = {
  _?: Maybe<Scalars['String']['output']>;
  activity?: Maybe<ActivityMutation>;
  jobExecution?: Maybe<JobExecutionMutationSchema>;
  pdfRenderer?: Maybe<PdfRendererMutation>;
  report?: Maybe<ReportMutation>;
  reportV2?: Maybe<ReportV2Mutation>;
  segment?: Maybe<SegmentMutation>;
  segmentV2?: Maybe<SegmentV2Mutation>;
  team?: Maybe<TeamMutation>;
  tracking?: Maybe<TrackingMutation>;
  user?: Maybe<UserMutation>;
};

export type OnboardingSearchInput = {
  country?: InputMaybe<SupportedCountry>;
  includeCategories?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  pageNum?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type OrderByOrders =
  | 'ASC'
  | 'DESC';

export type OrderDirection =
  | 'ASC'
  | 'DESC';

export type PageInfo = {
  currentPage: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type PartialIndustry = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PdfCreativeInput = {
  appUrl: Scalars['String']['input'];
  debug?: InputMaybe<Scalars['Boolean']['input']>;
  filter: Scalars['String']['input'];
  printConfig?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type PdfRendererDownloadLink = {
  downloadLink: Scalars['String']['output'];
};

export type PdfRendererMutation = {
  pdfCreative: PdfRendererDownloadLink;
  pdfReportCreate: PdfRendererDownloadLink;
};


export type PdfRendererMutationPdfCreativeArgs = {
  input: PdfCreativeInput;
};


export type PdfRendererMutationPdfReportCreateArgs = {
  input: PdfReportItemInput;
};

export type PdfReportItemInput = {
  debug?: InputMaybe<Scalars['Boolean']['input']>;
  printConfig?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['UUID']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type PeriodSelection = {
  comparisonPeriod?: InputMaybe<ComparisonPeriod>;
  config?: InputMaybe<PeriodSelectionConfig>;
  reportingPeriod: ReportingPeriod;
};

export type PeriodSelectionConfig = {
  comparisonPeriodName?: InputMaybe<Scalars['String']['input']>;
  groupByMonth?: InputMaybe<Scalars['Boolean']['input']>;
  reportingPeriodName?: InputMaybe<Scalars['String']['input']>;
};

export type Phrase = {
  constituents?: Maybe<Array<Maybe<PhrasePart>>>;
  text?: Maybe<Scalars['String']['output']>;
};

export type PhrasePart = {
  meta?: Maybe<Scalars['JSON']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type PlacementType = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProductNameInput = {
  brand: Scalars['String']['input'];
  product: Scalars['String']['input'];
};

export type ProductV2 = {
  brand?: Maybe<BrandV2>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PublicCreativeItemV2 = {
  adType?: Maybe<AdTypeV2>;
  advertiserDomain?: Maybe<Scalars['String']['output']>;
  advertiserName?: Maybe<Scalars['String']['output']>;
  brandList: Array<BrandV2>;
  categoryList: Array<CategoryV2>;
  country?: Maybe<Scalars['String']['output']>;
  creative: Scalars['String']['output'];
  firstAppeared?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  industryList: Array<IndustryV2>;
  lastAppeared?: Maybe<Scalars['Date']['output']>;
  mediaOwnerList: Array<MediaOwner>;
  mediaTypeList: Array<MediaTypeV2>;
  placementTypes: Array<Scalars['String']['output']>;
  productList: Array<ProductV2>;
  publicationList: Array<PublicationV2>;
  publisherList: Array<Publisher>;
  regionList: Array<RegionV2>;
  status: CreativeStatusV2;
};

export type PublicationV2 = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Publisher = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  _?: Maybe<Scalars['String']['output']>;
  activity?: Maybe<ActivityQuery>;
  brandScreen?: Maybe<BrandScreen>;
  changeset?: Maybe<ChangesetQuery>;
  creativeV2?: Maybe<CreativeQueryV2>;
  dashboardV2?: Maybe<DashboardQueryV2>;
  dashboardV3?: Maybe<DashboardQueryV3>;
  filterV2?: Maybe<FilterQueryV2>;
  graph?: Maybe<GraphResult>;
  jobExecution?: Maybe<JobExecutionQuerySchema>;
  mediaValue: MediaValueQuery;
  meta?: Maybe<MetaQuery>;
  release?: Maybe<ReleaseQuery>;
  report?: Maybe<ReportQuery>;
  reportV2?: Maybe<ReportV2Query>;
  reportV3?: Maybe<ReportV3Query>;
  search: SearchPage;
  searchV2?: Maybe<SearchV2Query>;
  segment?: Maybe<SegmentQuery>;
  segmentV2?: Maybe<SegmentV2Query>;
  team?: Maybe<TeamQuery>;
  user?: Maybe<UserQuery>;
  viewer?: Maybe<ViewerQuery>;
};


export type QueryGraphArgs = {
  filter?: InputMaybe<GraphFilter>;
  lens: Array<InputMaybe<GraphDimension>>;
  metric: GraphMetric;
  node: GraphNode;
};


export type QuerySearchArgs = {
  search?: InputMaybe<SearchInput>;
};

export type RegionV2 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Release = {
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ReleaseOrderBy = {
  field: ReleaseOrderByFields;
  order: OrderDirection;
};

export type ReleaseOrderByFields =
  | 'createdAt'
  | 'name'
  | 'updatedAt';

export type ReleasePageInfo = {
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type ReleasePagination = {
  count: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type ReleaseQuery = {
  search: ReleaseResult;
};


export type ReleaseQuerySearchArgs = {
  orderBy?: InputMaybe<ReleaseOrderBy>;
  pagination?: InputMaybe<ReleasePagination>;
  query?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ReleaseStatus>>;
};

export type ReleaseResult = {
  items: Array<Release>;
  pageInfo: ReleasePageInfo;
};

export type ReleaseStatus =
  | 'ARCHIVED'
  | 'DRAFT'
  | 'DRAFT_PREPARE_FAILED'
  | 'DRAFT_PREPARING'
  | 'DRAFT_READY'
  | 'LIVE'
  | 'PREVIOUS';

export type RemoveTeamInput = {
  teamId: Scalars['UUID']['input'];
};

export type RemoveTeamInviteInput = {
  inviteId: Scalars['UUID']['input'];
};

export type RemoveTeamMemberInput = {
  teamId: Scalars['UUID']['input'];
  userId: Scalars['String']['input'];
};

export type Report = ReportSchema & {
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconColor: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  items: Array<ReportItem>;
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  sharing: ReportSharing;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<ReportUser>;
};

export type ReportChangeOwnershipInput = {
  id: Scalars['UUID']['input'];
  newTeamId: Scalars['UUID']['input'];
  newUserId: Scalars['String']['input'];
};

export type ReportCreateInput = {
  country: Scalars['String']['input'];
  iconColor: Scalars['String']['input'];
  iconName: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ReportItem = {
  createdAt: Scalars['DateTime']['output'];
  definition: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  type: ReportItemType;
  updateAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ReportItemCreateInput = {
  definition: Scalars['JSON']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['UUID']['input'];
  type: ReportItemType;
};

export type ReportItemGetInput = {
  id: Scalars['UUID']['input'];
  reportId: Scalars['UUID']['input'];
};

export type ReportItemRemoveInput = {
  id: Scalars['UUID']['input'];
  reportId: Scalars['UUID']['input'];
};

export type ReportItemType =
  | 'AdvertisingCreative'
  | 'GrowthAnalysis'
  | 'MediaValue'
  | 'VerticalAnalysis';

export type ReportItemUpdateInput = {
  definition: Scalars['JSON']['input'];
  description: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['UUID']['input'];
  type: ReportItemType;
};

export type ReportItemV2 = {
  createdAt: Scalars['DateTime']['output'];
  definition?: Maybe<Scalars['JSON']['output']>;
  definitionV2: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  type: ReportItemType;
  updateAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ReportItemV2CreateInput = {
  definitionV2: Scalars['JSON']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['UUID']['input'];
  type: ReportItemType;
};

export type ReportItemV2UpdateInput = {
  definitionV2: Scalars['JSON']['input'];
  description: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['UUID']['input'];
  type: ReportItemType;
};

export type ReportListFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportListInput = {
  country: Scalars['String']['input'];
  filter?: InputMaybe<ReportListFilter>;
  orderBy?: InputMaybe<ReportListOrderBy>;
  pagination?: InputMaybe<ReportListPagination>;
};

export type ReportListItem = ReportSchema & {
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconColor: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  sharing: ReportSharing;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<ReportUser>;
};

export type ReportListOrderBy = {
  field: ReportListOrderField;
  order: OrderByOrders;
};

export type ReportListOrderField =
  | 'fullName'
  | 'name'
  | 'teamName'
  | 'updatedAt';

export type ReportListPagination = {
  count: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type ReportListPaginationResult = {
  count: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  page: Scalars['Int']['output'];
};

export type ReportListResult = {
  items: Array<ReportListItem>;
  pagination: ReportListPaginationResult;
};

export type ReportMutation = {
  reportCreate: Report;
  reportDuplicate?: Maybe<Report>;
  reportItemCreate: ReportItem;
  reportItemRemove: Scalars['Boolean']['output'];
  reportItemUpdate?: Maybe<ReportItem>;
  reportRemove: Scalars['Boolean']['output'];
  reportUpdate?: Maybe<Report>;
};


export type ReportMutationReportCreateArgs = {
  input: ReportCreateInput;
};


export type ReportMutationReportDuplicateArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportMutationReportItemCreateArgs = {
  input: ReportItemCreateInput;
};


export type ReportMutationReportItemRemoveArgs = {
  input: ReportItemRemoveInput;
};


export type ReportMutationReportItemUpdateArgs = {
  input: ReportItemUpdateInput;
};


export type ReportMutationReportRemoveArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportMutationReportUpdateArgs = {
  input: ReportUpdateInput;
};

export type ReportQuery = {
  report: Report;
  reportItem: ReportItem;
  reportList: Array<ReportListItem>;
};


export type ReportQueryReportArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportQueryReportItemArgs = {
  input: ReportItemGetInput;
};

export type ReportSchema = {
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconColor: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  sharing: ReportSharing;
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<ReportUser>;
};

export type ReportSearchInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['UUID']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ReportShareManyInput = {
  ids: Array<Scalars['UUID']['input']>;
  sharedStatus: SharedStatus;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReportSharing = {
  status: SharedStatus;
  usersWithAccess: Array<SharedUser>;
};

export type ReportUpdateInput = {
  description: Scalars['String']['input'];
  iconColor: Scalars['String']['input'];
  iconName: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  itemsSort?: InputMaybe<Array<Scalars['UUID']['input']>>;
  name: Scalars['String']['input'];
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  sharedStatus?: InputMaybe<SharedStatus>;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ReportUpdateManyInput = {
  reports: Array<ReportUpdateInput>;
};

export type ReportUser = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReportV2 = ReportSchema & {
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconColor: Scalars['String']['output'];
  iconName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  items: Array<ReportItemV2>;
  name: Scalars['String']['output'];
  shared: Scalars['Boolean']['output'];
  sharing: ReportSharing;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<ReportUser>;
};

export type ReportV2Mutation = {
  reportChangeOwnership: ChangeOwnershipData;
  reportCreate: ReportV2;
  reportDuplicate?: Maybe<ReportV2>;
  reportItemCreate: ReportItemV2;
  reportItemRemove: Scalars['Boolean']['output'];
  reportItemUpdate: ReportItemV2;
  reportRemove: Scalars['Boolean']['output'];
  reportRemoveMany?: Maybe<Array<Maybe<ReportListItem>>>;
  reportShareMany?: Maybe<Array<Maybe<ReportListItem>>>;
  reportUpdate?: Maybe<ReportV2>;
};


export type ReportV2MutationReportChangeOwnershipArgs = {
  input: ReportChangeOwnershipInput;
};


export type ReportV2MutationReportCreateArgs = {
  input: ReportCreateInput;
};


export type ReportV2MutationReportDuplicateArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportV2MutationReportItemCreateArgs = {
  input: ReportItemV2CreateInput;
};


export type ReportV2MutationReportItemRemoveArgs = {
  input: ReportItemRemoveInput;
};


export type ReportV2MutationReportItemUpdateArgs = {
  input: ReportItemV2UpdateInput;
};


export type ReportV2MutationReportRemoveArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportV2MutationReportRemoveManyArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type ReportV2MutationReportShareManyArgs = {
  input: ReportShareManyInput;
};


export type ReportV2MutationReportUpdateArgs = {
  input: ReportUpdateInput;
};

export type ReportV2Query = {
  report: ReportV2;
  reportItem: ReportItemV2;
  reportList: Array<ReportListItem>;
  reportSearch: Array<ReportListItem>;
};


export type ReportV2QueryReportArgs = {
  id: Scalars['UUID']['input'];
};


export type ReportV2QueryReportItemArgs = {
  input: ReportItemGetInput;
};


export type ReportV2QueryReportListArgs = {
  input: ReportListInput;
};


export type ReportV2QueryReportSearchArgs = {
  input: ReportSearchInput;
};

export type ReportV3Query = {
  reportList: ReportListResult;
};


export type ReportV3QueryReportListArgs = {
  input: ReportListInput;
};

export type ReportingPeriod = {
  absolute?: InputMaybe<AbsoluteDateRangeFilter>;
  relative?: InputMaybe<MediaValueRelativeDateRangeFilter>;
};

export type ResendTeamInviteInput = {
  inviteId: Scalars['UUID']['input'];
};

export type ResetDownloadCountInput = {
  id: Scalars['UUID']['input'];
};

export type ResultColumn =
  | 'adType'
  | 'brand'
  | 'campaignId'
  | 'category'
  | 'creativeTheme'
  | 'industry'
  | 'mediaType'
  | 'product'
  | 'region'
  | 'segment';

export type ResultType =
  | 'brand'
  | 'campaign'
  | 'category'
  | 'industry'
  | 'mediaNetwork'
  | 'product'
  | 'publication'
  | 'subcategory'
  | 'variation';

export type ResultTypeV2 =
  | 'brand'
  | 'category'
  | 'industry';

export type SearchInput = {
  includeTypes: Array<ResultType>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limitToViewerIndustries?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<SearchInputOrderBy>;
  orderDirection?: InputMaybe<SearchInputOrderDirection>;
  pageNum?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type SearchInputOrderBy =
  | 'alphabetical'
  | 'spend';

export type SearchInputOrderDirection =
  | 'ASC'
  | 'DESC';

export type SearchInputV2 = {
  country?: InputMaybe<SupportedCountry>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  limitToViewerIndustries?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<SearchInputOrderBy>;
  orderDirection?: InputMaybe<SearchInputOrderDirection>;
  pageNum?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type: ResultTypeV2;
};

export type SearchItem = {
  hasAccess?: Maybe<Scalars['Boolean']['output']>;
  industries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  meta?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<ResultType>;
};

export type SearchItemV2 = {
  hasAccess: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  industries?: Maybe<Array<PartialIndustry>>;
  name: Scalars['String']['output'];
  type: ResultTypeV2;
};

export type SearchPage = {
  items: Array<Maybe<SearchItem>>;
  pageInfo: PageInfo;
};

export type SearchPageV2 = {
  items: Array<SearchItemV2>;
  pageInfo: PageInfo;
};

export type SearchV2Query = {
  onboardingSearch: SearchPageV2;
  search: SearchPageV2;
  uniqueIndustryGroupsSearch: UniqueIndustryGroupsSearchResult;
};


export type SearchV2QueryOnboardingSearchArgs = {
  search: OnboardingSearchInput;
};


export type SearchV2QuerySearchArgs = {
  search?: InputMaybe<SearchInputV2>;
};


export type SearchV2QueryUniqueIndustryGroupsSearchArgs = {
  search?: InputMaybe<UniqueIndustryGroupsSearchInput>;
};

export type Segment = SegmentSchema & {
  author: SharedUser;
  color: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  definition: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sharing: SegmentSharing;
  status?: Maybe<DeletedStatus>;
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SegmentCreateInput = {
  color: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  definition?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sharedStatus?: InputMaybe<SharedStatus>;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SegmentListItem = {
  data: Array<Maybe<Segment>>;
};

export type SegmentListOptions = {
  country?: InputMaybe<Scalars['String']['input']>;
  idList?: InputMaybe<Array<Scalars['String']['input']>>;
  ignoreSharing?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  requestFullPrimitives?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SegmentMutation = {
  segmentCreate: Segment;
  segmentRemove?: Maybe<Scalars['Boolean']['output']>;
  segmentUpdate?: Maybe<Segment>;
};


export type SegmentMutationSegmentCreateArgs = {
  input: SegmentCreateInput;
};


export type SegmentMutationSegmentRemoveArgs = {
  id: Scalars['UUID']['input'];
};


export type SegmentMutationSegmentUpdateArgs = {
  input: SegmentUpdateInput;
};

export type SegmentQuery = {
  segment: Segment;
  segmentList: SegmentListItem;
};


export type SegmentQuerySegmentArgs = {
  id: Scalars['UUID']['input'];
  ignoreSharing?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type SegmentQuerySegmentListArgs = {
  input?: InputMaybe<SegmentListOptions>;
};

export type SegmentSchema = {
  author?: Maybe<SharedUser>;
  color: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  definition?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sharing: SegmentSharing;
  status?: Maybe<DeletedStatus>;
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SegmentSearchInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['UUID']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SegmentSharing = {
  status: SharedStatus;
  usersWithAccess: Array<SharedUser>;
};

export type SegmentUpdateInput = {
  color: Scalars['String']['input'];
  definition: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  sharedStatus?: InputMaybe<SharedStatus>;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SegmentV2 = SegmentSchema & {
  author: SharedUser;
  color: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  definition?: Maybe<Scalars['JSON']['output']>;
  definitionV2: Scalars['JSON']['output'];
  description?: Maybe<Scalars['String']['output']>;
  emailAlert: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sharing: SegmentSharing;
  status?: Maybe<DeletedStatus>;
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SegmentV2ChangeOwnership = {
  id: Scalars['UUID']['input'];
  newTeamId: Scalars['UUID']['input'];
  newUserId: Scalars['String']['input'];
};

export type SegmentV2CreateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  definitionV2?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailAlert?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  sharedStatus?: InputMaybe<SharedStatus>;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SegmentV2ListItem = {
  author: SharedUser;
  color: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  definitionV2?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  emailAlert?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  sharing: SegmentSharing;
  status?: Maybe<DeletedStatus>;
  teamId: Scalars['UUID']['output'];
  teamName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SegmentV2ListItems = {
  data: Array<Maybe<SegmentV2ListItem>>;
};

export type SegmentV2Mutation = {
  creativeEmailSegmentUpdate?: Maybe<SegmentV2>;
  firstNonAuSegmentCreate?: Maybe<SegmentV2>;
  nzUnsubscribe?: Maybe<Scalars['Boolean']['output']>;
  segmentChangeOwnership?: Maybe<SegmentV2>;
  segmentCreate: SegmentV2;
  segmentRemove?: Maybe<Scalars['Boolean']['output']>;
  segmentRemoveMany: SegmentV2ListItems;
  segmentShareMany: SegmentV2ListItems;
  segmentUpdate?: Maybe<SegmentV2>;
};


export type SegmentV2MutationCreativeEmailSegmentUpdateArgs = {
  input: CreativeEmailSegmentUpdateInput;
};


export type SegmentV2MutationFirstNonAuSegmentCreateArgs = {
  input: FirstNonAuSegmentCreateInput;
};


export type SegmentV2MutationNzUnsubscribeArgs = {
  teamId: Scalars['UUID']['input'];
};


export type SegmentV2MutationSegmentChangeOwnershipArgs = {
  input: SegmentV2ChangeOwnership;
};


export type SegmentV2MutationSegmentCreateArgs = {
  input: SegmentV2CreateInput;
};


export type SegmentV2MutationSegmentRemoveArgs = {
  id: Scalars['UUID']['input'];
};


export type SegmentV2MutationSegmentRemoveManyArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type SegmentV2MutationSegmentShareManyArgs = {
  input: SegmentV2ShareManyInput;
};


export type SegmentV2MutationSegmentUpdateArgs = {
  input: SegmentV2UpdateInput;
};

export type SegmentV2Query = {
  creativeEmailSegment?: Maybe<SegmentV2>;
  segment: SegmentV2;
  segmentList: SegmentV2ListItems;
  segmentSearch: SegmentV2ListItems;
};


export type SegmentV2QueryCreativeEmailSegmentArgs = {
  input?: InputMaybe<CreativeEmailSegmentInput>;
};


export type SegmentV2QuerySegmentArgs = {
  id: Scalars['UUID']['input'];
  ignoreSharing?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SegmentV2QuerySegmentListArgs = {
  input?: InputMaybe<SegmentListOptions>;
};


export type SegmentV2QuerySegmentSearchArgs = {
  input: SegmentSearchInput;
};

export type SegmentV2ShareManyInput = {
  ids: Array<Scalars['UUID']['input']>;
  sharedStatus: SharedStatus;
  userIdsWithAccess: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SegmentV2UpdateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  definitionV2: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  emailAlert?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  sharedStatus?: InputMaybe<SharedStatus>;
  userIdsWithAccess?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ShallowReportItem = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  teamId: Scalars['UUID']['output'];
  type: ReportItemType;
  updateAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type SharedStatus =
  | 'private'
  | 'specificTeamMembers'
  | 'wholeTeam';

export type SharedUser = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StatusFilterV2 = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SupportedCountry =
  | 'AU'
  | 'NZ';

export type Team = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  invites: Array<UserInvite>;
  members: Array<TeamMember>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  products?: Maybe<Array<Maybe<TeamProduct>>>;
  status?: Maybe<TeamStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TeamInvitesArgs = {
  status: Array<UserInviteStatus>;
};

export type TeamAdminProducts =
  | 'DataEntry'
  | 'TeamAdmin'
  | 'Termatico';

export type TeamMember = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role: TeamMemberRole;
  status?: Maybe<TeamMemberStatus>;
  team?: Maybe<Team>;
  teamId: Scalars['UUID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type TeamMemberRole =
  | 'MEMBER'
  | 'OWNER';

export type TeamMemberStatus =
  | 'DELETED';

export type TeamMutation = {
  teamAddNzTrial: Team;
  teamAddUser?: Maybe<TeamMember>;
  teamCreateWithInvite?: Maybe<Team>;
  teamIncrementDownloadCount?: Maybe<Team>;
  teamInviteUser?: Maybe<UserInvite>;
  teamRemove: Scalars['Boolean']['output'];
  teamRemoveInvite: Scalars['Boolean']['output'];
  teamRemoveUser: Scalars['Boolean']['output'];
  teamResendInvite: Scalars['Boolean']['output'];
  teamResetDownloadCount?: Maybe<Team>;
  teamUpdate?: Maybe<Team>;
  teamUpdateProducts?: Maybe<Array<Maybe<TeamProduct>>>;
  teamUpdateTrialIndustryGroup: Team;
  teamUpdateUser?: Maybe<TeamMember>;
};


export type TeamMutationTeamAddNzTrialArgs = {
  mutation: AddNzTrialInput;
};


export type TeamMutationTeamAddUserArgs = {
  mutation: AddUserToTeamInput;
};


export type TeamMutationTeamCreateWithInviteArgs = {
  mutation: CreateTeamWithInviteInput;
};


export type TeamMutationTeamIncrementDownloadCountArgs = {
  mutation: IncrementDownloadCountInput;
};


export type TeamMutationTeamInviteUserArgs = {
  mutation: InviteUserToTeamInput;
};


export type TeamMutationTeamRemoveArgs = {
  mutation: RemoveTeamInput;
};


export type TeamMutationTeamRemoveInviteArgs = {
  mutation: RemoveTeamInviteInput;
};


export type TeamMutationTeamRemoveUserArgs = {
  mutation: RemoveTeamMemberInput;
};


export type TeamMutationTeamResendInviteArgs = {
  mutation: ResendTeamInviteInput;
};


export type TeamMutationTeamResetDownloadCountArgs = {
  mutation: ResetDownloadCountInput;
};


export type TeamMutationTeamUpdateArgs = {
  mutation: UpdateTeamInput;
};


export type TeamMutationTeamUpdateProductsArgs = {
  mutation: UpdateTeamProductInput;
};


export type TeamMutationTeamUpdateTrialIndustryGroupArgs = {
  mutation: UpdateTeamIndustryGroupInput;
};


export type TeamMutationTeamUpdateUserArgs = {
  mutation: UpdateTeamMemberInput;
};

export type TeamProduct = {
  country?: Maybe<Scalars['String']['output']>;
  hasSelectedIndustries?: Maybe<Scalars['Boolean']['output']>;
  industries?: Maybe<Array<Scalars['String']['output']>>;
  industryIds?: Maybe<Array<Scalars['String']['output']>>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  product: Scalars['String']['output'];
  status?: Maybe<TeamProductStatus>;
};

export type TeamProductInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  industryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  product: TeamProductType;
  status: TeamProductStatus;
};

export type TeamProductStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'TRIALING';

export type TeamProductStatusFilter =
  | 'ACTIVE'
  | 'ADMIN'
  | 'INACTIVE'
  | 'TRIALING';

export type TeamProductStatuses =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'TRIALING';

export type TeamProductType =
  | 'AdvertisingCreative'
  | 'DataEntry'
  | 'MediaValue'
  | 'TeamAdmin'
  | 'Termatico';

export type TeamQuery = {
  team: Team;
  teamList: Array<Team>;
};


export type TeamQueryTeamArgs = {
  teamId: Scalars['UUID']['input'];
};


export type TeamQueryTeamListArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TeamProductStatusFilter>;
};

export type TeamStatus =
  | 'DELETED';

export type TrackingFormField = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TrackingMutation = {
  submitHubspotForm?: Maybe<Scalars['Boolean']['output']>;
  submitInternationalTeamOnboardedForm?: Maybe<Scalars['Boolean']['output']>;
  submitUserCreatedAccountForm?: Maybe<Scalars['Boolean']['output']>;
};


export type TrackingMutationSubmitHubspotFormArgs = {
  formId: Scalars['String']['input'];
  formInput: Array<TrackingFormField>;
};


export type TrackingMutationSubmitInternationalTeamOnboardedFormArgs = {
  country: Scalars['String']['input'];
};


export type TrackingMutationSubmitUserCreatedAccountFormArgs = {
  country: SupportedCountry;
};

export type UniqueIndustryGroupsSearchInput = {
  query?: InputMaybe<Scalars['String']['input']>;
};

export type UniqueIndustryGroupsSearchResult = {
  items: Array<Scalars['String']['output']>;
};

export type UpdateTeamIndustryGroupInput = {
  industryGroup: Scalars['String']['input'];
  teamId: Scalars['UUID']['input'];
};

export type UpdateTeamInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateTeamMemberInput = {
  role: TeamMemberRole;
  teamId: Scalars['UUID']['input'];
  userId: Scalars['String']['input'];
};

export type UpdateTeamProductInput = {
  teamId: Scalars['UUID']['input'];
  teamProducts: Array<TeamProductInput>;
};

export type User = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  memberships: Array<TeamMember>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  productMap?: Maybe<UserProductMap>;
  products: Array<UserProduct>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UserInvite = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inviter?: Maybe<User>;
  role: TeamMemberRole;
  status: UserInviteStatus;
  team?: Maybe<Team>;
  username: Scalars['String']['output'];
};

export type UserInviteStatus =
  | 'ACCEPTED'
  | 'AUTO_ACCEPTED'
  | 'DELETED'
  | 'PENDING';

export type UserListFilter = {
  adminProduct?: InputMaybe<TeamAdminProducts>;
  australianCreative?: InputMaybe<TeamProductStatuses>;
  australianMediaValue?: InputMaybe<TeamProductStatuses>;
  newZealandCreative?: InputMaybe<TeamProductStatuses>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserListOrderBy = {
  direction?: InputMaybe<UserListOrderByDirection>;
  field?: InputMaybe<UserListOrderByField>;
};

export type UserListOrderByDirection =
  | 'ASC'
  | 'DESC';

export type UserListOrderByField =
  | 'createdAt'
  | 'firstname'
  | 'lastname'
  | 'updatedAt'
  | 'username';

export type UserListPagination = {
  count?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMutation = {
  userUpdate?: Maybe<User>;
  userUpdateSessionMetadata?: Maybe<User>;
};


export type UserMutationUserUpdateArgs = {
  mutation: UserUpdateInput;
};


export type UserMutationUserUpdateSessionMetadataArgs = {
  mutation: UserUpdateSessionMetadataInput;
};

export type UserOnboardingInfo = {
  isAustraliaOnboarded: Scalars['Boolean']['output'];
  isNewZealandOnboarded?: Maybe<Scalars['Boolean']['output']>;
};

export type UserProduct = {
  product: UserProductType;
  status: Scalars['String']['output'];
};

export type UserProductBigdatrPrototype = {
  product: UserProductBigdatrPrototypeType;
  status: UserProductBigdatrPrototypeStatus;
};

export type UserProductBigdatrPrototypeStatus =
  | 'ACTIVE'
  | 'DELETED';

export type UserProductBigdatrPrototypeType =
  | 'BigdatrPrototype';

export type UserProductMap = {
  BigdatrPrototype?: Maybe<UserProductBigdatrPrototype>;
};

export type UserProductType =
  | 'BigdatrPrototype';

export type UserQuery = {
  user: User;
  userList: Array<User>;
};


export type UserQueryUserArgs = {
  userId: Scalars['String']['input'];
};


export type UserQueryUserListArgs = {
  filter?: InputMaybe<UserListFilter>;
  orderBy?: InputMaybe<UserListOrderBy>;
  pagination?: InputMaybe<UserListPagination>;
};

export type UserStatus = {
  adminProducts?: Maybe<Array<Maybe<TeamAdminProducts>>>;
  australianCreative: TeamProductStatuses;
  australianMediaValue: TeamProductStatuses;
  newZealandCreative: TeamProductStatuses;
};

export type UserUpdateInput = {
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Scalars['Int']['input']>;
};

export type UserUpdateSessionMetadataInput = {
  id: Scalars['String']['input'];
  sessionMetadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type ViewerQuery = {
  forecastCutoff?: Maybe<Scalars['String']['output']>;
  forecastCutoffDigital?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  maxDate?: Maybe<Scalars['String']['output']>;
  teams: Array<ViewerTeam>;
  user: ViewerUser;
  userOnboardingInfo: UserOnboardingInfo;
};

export type ViewerTeam = {
  downloadCount?: Maybe<Scalars['Int']['output']>;
  hasTrialTeamName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  products: Array<TeamProduct>;
  requestedNzTrial?: Maybe<Scalars['Boolean']['output']>;
  role: TeamMemberRole;
  trialExpired?: Maybe<Scalars['Boolean']['output']>;
};

export type ViewerUser = {
  createdAt: Scalars['DateTime']['output'];
  firstname: Scalars['String']['output'];
  hasDataIngestAccess?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationStatus?: Maybe<Scalars['String']['output']>;
  sessionMetadata?: Maybe<Scalars['JSON']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
  username: Scalars['String']['output'];
};

export type FilterQueryQueryVariables = Exact<{
  input: FilterInputV2;
  filter?: InputMaybe<FilterForFilteringFiltersV2>;
}>;


export type FilterQueryQuery = { filterV2?: { filters?: { column: FilterColumnV2, pageInfo: { currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean }, items: { adType?: Array<{ id: string, name: string, canonicalName: string }> | null, advertiserDomain?: Array<{ id: string, name: string }> | null, advertiserName?: Array<{ id: string, name: string }> | null, brand?: Array<{ id: string, name: string, industries?: Array<{ id: string, name: string }> | null }> | null, industry?: Array<{ id: string, name: string }> | null, category?: Array<{ id: string, name: string, industry?: { id: string, name: string } | null }> | null, campaign?: Array<{ id: string, name: string }> | null, publication?: Array<{ id: string, name: string }> | null, publisher?: Array<{ id: string, name: string }> | null, region?: Array<{ id: string, name: string }> | null, product?: Array<{ id: string, name: string, brand?: { id: string, name: string } | null }> | null, mediaOwner?: Array<{ id: string, name: string }> | null, mediaType?: Array<{ id: string, name: string, canonicalName: string }> | null, release?: Array<{ id: string, name: string, status: string }> | null, changeset?: Array<{ id: string, name: string, status: string }> | null, segment?: Array<{ id: string, name: string, color: string, emailAlert: boolean, teamId: string, createdAt: string, updatedAt: string, sharing: { status: SharedStatus, usersWithAccess: Array<{ id: string, name: string, email: string }> }, author: { id: string, name: string, email: string } }> | null, location?: Array<{ id: string, name: string }> | null, status?: Array<{ id: string, name: string }> | null, placementType?: Array<{ id: string, name: string }> | null } } | null } | null };

export type CreativeEmailSegmentCreateMutationVariables = Exact<{
  input: SegmentV2CreateInput;
}>;


export type CreativeEmailSegmentCreateMutation = { segmentV2?: { segmentCreate: { id: string, name: string, color: string, teamId: string, teamName?: string | null, description?: string | null, definitionV2: { [key: string]: any }, emailAlert: boolean, createdAt: string, updatedAt: string, status?: DeletedStatus | null, author: { id: string, name: string, email: string }, sharing: { status: SharedStatus, usersWithAccess: Array<{ id: string, name: string, email: string }> } } } | null };

export type CreativeEmailSegmentUpdateMutationVariables = Exact<{
  input: CreativeEmailSegmentUpdateInput;
}>;


export type CreativeEmailSegmentUpdateMutation = { segmentV2?: { creativeEmailSegmentUpdate?: { id: string, name: string, color: string, teamId: string, description?: string | null, definitionV2: { [key: string]: any }, emailAlert: boolean, createdAt: string, updatedAt: string, status?: DeletedStatus | null, author: { id: string, name: string, email: string }, sharing: { status: SharedStatus, usersWithAccess: Array<{ id: string, name: string, email: string }> } } | null } | null };

export type CreativeEmailSegmentQueryQueryVariables = Exact<{
  input?: InputMaybe<CreativeEmailSegmentInput>;
}>;


export type CreativeEmailSegmentQueryQuery = { segmentV2?: { creativeEmailSegment?: { id: string, name: string, color: string, teamId: string, description?: string | null, definitionV2: { [key: string]: any }, emailAlert: boolean, createdAt: string, updatedAt: string, status?: DeletedStatus | null, author: { id: string, name: string, email: string }, sharing: { status: SharedStatus, usersWithAccess: Array<{ id: string, name: string, email: string }> } } | null } | null };

export type ViewerQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQueryQuery = { viewer?: { id: string, forecastCutoff?: string | null, forecastCutoffDigital?: string | null, maxDate?: string | null, userOnboardingInfo: { isAustraliaOnboarded: boolean }, teams: Array<{ id: string, name: string, role: TeamMemberRole, trialExpired?: boolean | null, hasTrialTeamName?: boolean | null, requestedNzTrial?: boolean | null, downloadCount?: number | null, products: Array<{ product: string, industryIds?: Array<string> | null, industries?: Array<string> | null, status?: TeamProductStatus | null, metadata?: { [key: string]: any } | null, country?: string | null }> }>, user: { firstname: string, id: string, lastname: string, name: string, username: string, createdAt: string, registrationStatus?: string | null, sessionMetadata?: { [key: string]: any } | null } } | null };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any> | undefined) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const FilterQueryDocument = new TypedDocumentString(`
    query FilterQuery($input: FilterInputV2!, $filter: FilterForFilteringFiltersV2) {
  filterV2 {
    filters(input: $input, filter: $filter) {
      column
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
      }
      items {
        adType {
          id
          name
          canonicalName
        }
        advertiserDomain {
          id
          name
        }
        advertiserName {
          id
          name
        }
        brand {
          id
          name
          industries {
            id
            name
          }
        }
        industry {
          id
          name
        }
        category {
          id
          name
          industry {
            id
            name
          }
        }
        campaign {
          id
          name
        }
        publication {
          id
          name
        }
        publisher {
          id
          name
        }
        region {
          id
          name
        }
        product {
          id
          name
          brand {
            id
            name
          }
        }
        mediaOwner {
          id
          name
        }
        mediaType {
          id
          name
          canonicalName
        }
        release {
          id
          name
          status
        }
        changeset {
          id
          name
          status
        }
        segment {
          id
          name
          color
          emailAlert
          teamId
          createdAt
          updatedAt
          sharing {
            status
            usersWithAccess {
              id
              name
              email
            }
          }
          author {
            id
            name
            email
          }
        }
        location {
          id
          name
        }
        status {
          id
          name
        }
        placementType {
          id
          name
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<FilterQueryQuery, FilterQueryQueryVariables>;
export const CreativeEmailSegmentCreateDocument = new TypedDocumentString(`
    mutation CreativeEmailSegmentCreate($input: SegmentV2CreateInput!) {
  segmentV2 {
    segmentCreate(input: $input) {
      id
      name
      color
      teamId
      teamName
      description
      definitionV2
      emailAlert
      author {
        id
        name
        email
      }
      sharing {
        status
        usersWithAccess {
          id
          name
          email
        }
      }
      createdAt
      updatedAt
      status
    }
  }
}
    `) as unknown as TypedDocumentString<CreativeEmailSegmentCreateMutation, CreativeEmailSegmentCreateMutationVariables>;
export const CreativeEmailSegmentUpdateDocument = new TypedDocumentString(`
    mutation CreativeEmailSegmentUpdate($input: CreativeEmailSegmentUpdateInput!) {
  segmentV2 {
    creativeEmailSegmentUpdate(input: $input) {
      id
      name
      color
      teamId
      description
      definitionV2
      emailAlert
      author {
        id
        name
        email
      }
      sharing {
        status
        usersWithAccess {
          id
          name
          email
        }
      }
      createdAt
      updatedAt
      status
    }
  }
}
    `) as unknown as TypedDocumentString<CreativeEmailSegmentUpdateMutation, CreativeEmailSegmentUpdateMutationVariables>;
export const CreativeEmailSegmentQueryDocument = new TypedDocumentString(`
    query CreativeEmailSegmentQuery($input: CreativeEmailSegmentInput) {
  segmentV2 {
    creativeEmailSegment(input: $input) {
      id
      name
      color
      teamId
      description
      definitionV2
      emailAlert
      author {
        id
        name
        email
      }
      sharing {
        status
        usersWithAccess {
          id
          name
          email
        }
      }
      createdAt
      updatedAt
      status
    }
  }
}
    `) as unknown as TypedDocumentString<CreativeEmailSegmentQueryQuery, CreativeEmailSegmentQueryQueryVariables>;
export const ViewerQueryDocument = new TypedDocumentString(`
    query ViewerQuery {
  viewer {
    id
    forecastCutoff
    forecastCutoffDigital
    maxDate
    userOnboardingInfo {
      isAustraliaOnboarded
    }
    teams {
      id
      name
      role
      products {
        product
        industryIds
        industries
        status
        metadata
        country
      }
      trialExpired
      hasTrialTeamName
      requestedNzTrial
      downloadCount
    }
    user {
      firstname
      id
      lastname
      name
      username
      createdAt
      registrationStatus
      sessionMetadata
    }
  }
}
    `) as unknown as TypedDocumentString<ViewerQueryQuery, ViewerQueryQueryVariables>;