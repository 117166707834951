import * as SegmentApi from './segment';
import * as ViewerApi from './viewer';
import * as FilterApi from './filter';

const rqApi = {
    ...FilterApi,
    ...ViewerApi,
    ...SegmentApi
};

export default rqApi;
