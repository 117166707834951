import {useMutation, useQueryClient, useQuery, skipToken} from '@tanstack/react-query';
import {useAuth} from 'bigdatr-style';
import Segment, {SegmentDefinition} from '~/segment/data/Segment';
import {
    CreativeEmailSegmentCreate,
    CreativeEmailSegmentQuery,
    CreativeEmailSegmentUpdate
} from './graphqlQueries';
import {CreativeEmailSegmentQueryQueryVariables} from '~/graphql-react-query/graphql';
import useClientMainAuthorizedFetch from '../clientMainAuthorisedFetch';

function getCreativeEmailSegmentQueryKey(teamId: string | null) {
    return ['creativeEmailSegment', teamId];
}

type CreativeEmailSegmentCreateInput = {
    brand?: string[];
    category?: string[];
    emailAlert?: boolean;
    teamId: string;
};
/**
 * creates an australian daily creative email segment and invalidates the viewer after its done.
 *
 * we invalidate the viewer because the whole app forces onboarding pages if the user isn't
 * onboarded, and that info is read from the viewer. we need to invalidate the viewer from the cache
 * (which forces a refetch), and then the app will allow users to leave onboarding
 */
export function useCreativeEmailSegmentCreate() {
    const request = useClientMainAuthorizedFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (input: CreativeEmailSegmentCreateInput) => {
            const response = await request(CreativeEmailSegmentCreate, {
                input: {
                    name: Segment.secretCreativeEmailSegmentName,
                    emailAlert: true, // enable notification by default
                    definitionV2: {
                        brand: input.brand,
                        category: input.category
                    }
                }
            });
            if (!response.segmentV2?.segmentCreate)
                throw new Error('Segment not returned from api');

            // refetch viewer so users can escape onboarding
            await queryClient.invalidateQueries({queryKey: ['viewer', input.teamId]});

            return new Segment(response.segmentV2.segmentCreate);
        },
        onSuccess: (data) => {
            queryClient.setQueryData(getCreativeEmailSegmentQueryKey(data.teamId), data);
        }
    });
}

type CreativeEmailSegmentInput = {
    id: string;
    brand?: string[];
    category?: string[];
    emailAlert?: boolean;
};
export function useCreativeEmailSegmentUpdate() {
    const request = useClientMainAuthorizedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (input: CreativeEmailSegmentInput) => {
            const response = await request(CreativeEmailSegmentUpdate, {
                input
            });
            if (!response.segmentV2?.creativeEmailSegmentUpdate)
                throw new Error('Segment not returned from api');

            return new Segment(response.segmentV2.creativeEmailSegmentUpdate);
        },
        onSuccess: (data) => {
            queryClient.setQueryData(getCreativeEmailSegmentQueryKey(data.teamId), data);
        }
    });
}

export function useCreativeEmailSegment(
    input?: CreativeEmailSegmentQueryQueryVariables,
    options?: {enabled: boolean}
) {
    const authData = useAuth();
    const request = useClientMainAuthorizedFetch();
    const enabled = options?.enabled ?? true;
    return useQuery({
        queryKey: getCreativeEmailSegmentQueryKey(authData.teamId),
        queryFn: enabled
            ? async () => {
                  const response = await request(CreativeEmailSegmentQuery, input);
                  if (!response.segmentV2?.creativeEmailSegment) return null;

                  return new Segment(response.segmentV2.creativeEmailSegment);
              }
            : skipToken
    });
}

type FollowBrandInput = {
    id: string;
    brand?: SegmentDefinition['brand'];
    category?: SegmentDefinition['category'];
    emailAlert?: boolean;
};

export function useFollowBrand() {
    const authData = useAuth();
    const request = useClientMainAuthorizedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        // this simulates an async generator with enty
        scope: {
            id: 'followBrand'
        },
        mutationFn: async (input: FollowBrandInput) => {
            const response = await request(CreativeEmailSegmentUpdate, {
                input: {
                    id: input.id,
                    brand: input.brand?.map((e) => e.id),
                    category: input.category?.map((e) => e.id),
                    emailAlert: input.emailAlert
                }
            });
            if (!response.segmentV2?.creativeEmailSegmentUpdate)
                throw new Error('Segment not returned from api');

            return new Segment(response.segmentV2.creativeEmailSegmentUpdate);
        },
        // optimistically update the daily email segment
        onMutate: async (nextPartialSegment) => {
            // Snapshot the previous value
            const cachedSegment = queryClient.getQueryData(
                getCreativeEmailSegmentQueryKey(authData.teamId)
            ) as Segment;

            const mergedSegment = new Segment({
                ...cachedSegment,
                definitionV2: {
                    ...cachedSegment?.definitionV2,
                    brand: [...(nextPartialSegment.brand || [])]
                }
            });

            // Optimistically update to the new value
            queryClient.setQueryData(
                getCreativeEmailSegmentQueryKey(authData.teamId),
                mergedSegment
            );

            // Return a context with the previous and new todo
            return {cachedSegment};
        },
        // If the mutation fails, use the context we returned above to rollback
        onError: (err, newTodo, context) => {
            if (context?.cachedSegment) {
                queryClient.setQueryData(
                    getCreativeEmailSegmentQueryKey(authData.teamId),
                    context.cachedSegment
                );
            }
        }
    });
}
