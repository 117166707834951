import {EntityStrict} from '~/app/Endpoints';
import {Brand, Creative, GraphqlRequest, Schemas} from 'bigdatr-style';
import {FollowedBrandsQueryVariables, TopBrandsQueryVariables} from '~/graphql-enty/graphql';
import {ArraySchema, ObjectSchema} from 'react-enty';
import {creativeList} from '~/creative/data/CreativeApi';

const followedBrands = EntityStrict(() => import('./DashboardFollowedBrandsQuery.graphql'));
const topBrands = EntityStrict(() => import('./DashboardTopBrandsQuery.graphql'));

type mediaValueMonthSpend = {
    month: string;
    spend: number;
};

export type DashboardBrands = {
    brands: {
        mediaValue: {
            previousPeriodTotal: number;
            currentPeriodTotal: number;
            change: number;
            hasAccess: boolean;
            currentPeriod: mediaValueMonthSpend[];
            previousPeriod: mediaValueMonthSpend[];
        };
        creatives: Creative[];
        brand: Brand;
    }[];
    paginationInfo: {
        currentPage: number;
        hasPreviousPage: boolean;
        hasNextPage: boolean;
    };
};

export type DashboardApiType = {
    dashboard: {
        topBrands: GraphqlRequest<
            {
                dashboardV3: {
                    topBrands: DashboardBrands;
                };
            },
            TopBrandsQueryVariables
        >;
        followedBrands: GraphqlRequest<
            {
                dashboardV3: {
                    followedBrands: DashboardBrands;
                };
            },
            FollowedBrandsQueryVariables
        >;
    };
};

export default {
    dashboard: {
        followedBrands,
        topBrands
    }
};

export const DashboardSchema = {
    dashboardV3: new ObjectSchema({
        topBrands: new ObjectSchema({
            brands: new ArraySchema(
                new ObjectSchema({
                    brand: Schemas.brand,
                    creatives: creativeList
                })
            )
        }),
        followedBrands: new ObjectSchema({
            brands: new ArraySchema(
                new ObjectSchema({
                    brand: Schemas.brand,
                    creatives: creativeList
                })
            )
        })
    })
};
