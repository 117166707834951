import {CreativeStatusType, TableSortState} from 'bigdatr-style';
import type {CreativeDisplay} from '~/feature/creative/display-toggle';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {
    ReportItemV2 as GraphqlReportItem,
    FilterColumnV2,
    ReportItemV2CreateInput,
    MediaValueInputColumn,
    PeriodSelection,
    MediaValueDateRangeFilter
} from '~/graphql-enty/graphql';

//
//
// Definitions

export const chartTypes = ['Stacked', 'Stacked %', 'Line', 'Matrix', 'Column', 'Ranking'] as const;
export type ChartType = (typeof chartTypes)[number];
export function isChartType(x: string): x is ChartType {
    return !!chartTypes.find((tt) => x === tt);
}

export type ColumnFilterV2 = {[K in FilterColumnV2]?: Array<string>};

export type AbsoluteDateRange = {startDate: string; endDate: string};
export type MediaDateRangeFilter = {
    truncateMonths?: number;
    absolute?: AbsoluteDateRange;
    relative?: {months: number; includeForecasted: boolean};
};

//
// Media value
//

//
// Breakdown
export type BreakdownColumnFilterUpsert = {
    segment?: string[];
    adType?: string[];
    brand?: string[];
    campaign?: string[];
    category?: string[];
    industry?: string[];
    mediaOwner?: string[];
    mediaType?: string[];
    product?: string[];
    publisher?: string[];
    region?: string[];
};

export type BreakdownColumnFilterDefinition = {
    segment?: {name: string; id: string}[];
    adType?: {name: string; id: string}[];
    brand?: {name: string; id: string}[];
    campaign?: {name: string; id: string}[];
    category?: {name: string; id: string}[];
    industry?: {name: string; id: string}[];
    mediaOwner?: {name: string; id: string}[];
    mediaType?: {name: string; id: string}[];
    product?: {name: string; id: string}[];
    publisher?: {name: string; id: string}[];
    region?: {name: string; id: string}[];
};

export type BreakdownDefinition = {
    filter: {
        columns: MediaValueInputColumn[];
        excludedColumns?: MediaValueInputColumn[];
        columnFilter: BreakdownColumnFilterDefinition;
        dateRangeFilter: MediaDateRangeFilter;
    };
    chartType: ChartType;
};

export type BreakdownDefinitionUpsert = {
    filter: {
        columns: MediaValueInputColumn[];
        excludedColumns?: MediaValueInputColumn[];
        columnFilter: BreakdownColumnFilterUpsert;
        dateRangeFilter: MediaDateRangeFilter;
    };
    chartType: ChartType;
};

export type GrowthAnalysisRowItemDefinition = {
    id: string;
    label: string;
    filter: {
        adType?: {name: string; id: string}[];
        brand?: {name: string; id: string}[];
        category?: {name: string; id: string}[];
        industry?: {name: string; id: string}[];
        mediaOwner?: {name: string; id: string}[];
        mediaType?: {name: string; id: string}[];
        product?: {name: string; id: string}[];
        publisher?: {name: string; id: string}[];
        region?: {name: string; id: string}[];
    };
};

export type GrowthAnalysisRowItemUpsertDefinition = {
    id: string;
    label: string;
    filter: {
        adType?: string[];
        brand?: string[];
        category?: string[];
        industry?: string[];
        mediaOwner?: string[];
        mediaType?: string[];
        product?: string[];
        publisher?: string[];
        region?: string[];
    };
};

export type GrowthAnalysisDefinition = {
    periodSelection: PeriodSelection;
    rows: GrowthAnalysisRowItemDefinition[];
};

export type GrowthAnalysisUpsertDefinition = {
    periodSelection: PeriodSelection;
    rows: GrowthAnalysisRowItemUpsertDefinition[];
};

export type VerticalAnalysisBreakdownColumn =
    | 'adType'
    | 'brand'
    | 'category'
    | 'industry'
    | 'mediaOwner'
    | 'mediaType'
    | 'product'
    | 'publisher'
    | 'region';

export type VerticalAnalysisRowDefinition = {
    id: string;
    label: string;
    filter: {
        adType?: {id: string; name: string}[];
        brand?: {id: string; name: string}[];
        category?: {id: string; name: string}[];
        industry?: {id: string; name: string}[];
        mediaOwner?: {id: string; name: string}[];
        mediaType?: {id: string; name: string}[];
        product?: {id: string; name: string}[];
        publisher?: {id: string; name: string}[];
        region?: {id: string; name: string}[];
    };
};

export type VerticalAnalysisRowUpsertDefinition = {
    id: string;
    label: string;
    filter: {
        adType?: string[];
        brand?: string[];
        category?: string[];
        industry?: string[];
        mediaOwner?: string[];
        mediaType?: string[];
        product?: string[];
        publisher?: string[];
        region?: string[];
    };
};

export type VerticalAnalysisDefinition = {
    dateRangeFilter: MediaValueDateRangeFilter;
    breakdownColumn: VerticalAnalysisBreakdownColumn;
    rows: VerticalAnalysisRowDefinition[];
};

export type VerticalAnalysisUpsertDefinition = {
    dateRangeFilter: MediaValueDateRangeFilter;
    breakdownColumn: VerticalAnalysisBreakdownColumn;
    rows: VerticalAnalysisRowUpsertDefinition[];
};

//
// Creative
//

export type CreativeDateRangeFilter = {
    absolute?: AbsoluteDateRange;
    relative?: {months: number};
};

/** @deprecated we use `CreativeDateRangeFilter now, but still need it in-app because
 * reports can have old definitions in them*/
export type CreativeDateRange = {
    min: string;
    max: string;
};

export type CreativeColumns =
    | 'adType'
    | 'brand'
    | 'campaign'
    | 'category'
    | 'industry'
    | 'mediaOwner'
    | 'mediaType'
    | 'placementType'
    | 'product'
    | 'publication'
    | 'publisher'
    | 'region'
    | 'segment'
    | 'status';

export type AdvertisingCreativeDefinition = {
    columns: CreativeColumns[];
    filter: {
        dateRangeFilter?: CreativeDateRangeFilter;
        status?: CreativeStatusType[];
        placementType?: string[];
        searchQuery?: string;
        advertiserName?: string[];
        advertiserDomain?: string[];

        // entities
        adType?: {name: string; id: string}[];
        campaign?: {name: string; id: string}[];
        industry?: {name: string; id: string}[];
        category?: {name: string; id: string; industry?: {id: string; name: string}}[];
        mediaOwner?: {name: string; id: string}[];
        mediaType?: {name: string; id: string}[];
        product?: {name: string; id: string}[];
        publication?: {name: string; id: string}[];
        publisher?: {name: string; id: string}[];
        region?: {name: string; id: string}[];
        brand?: {name: string; id: string}[];
        segment?: {name: string; id: string}[];
    };
    chartType: CreativeDisplay;
    orderBy?: CreativeListOrderBy;
    tableSort?: TableSortState;
};

export type CreativeReportItemUpsertInput = {
    columns: CreativeColumns[];
    filter: {
        dateRangeFilter?: CreativeDateRangeFilter;
        status?: CreativeStatusType[];
        placementType?: string[];
        adType?: string[];
        campaign?: string[];
        industry?: string[];
        category?: string[];
        mediaOwner?: string[];
        mediaType?: string[];
        product?: string[];
        publication?: string[];
        publisher?: string[];
        region?: string[];
        brand?: string[];
        segment?: string[];
        searchQuery?: string;
        advertiserName?: string[];
        advertiserDomain?: string[];
    };
    chartType: CreativeDisplay;
    orderBy?: CreativeListOrderBy;
    tableSort?: TableSortState;
};

//
//
// Items

export type BreakdownItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'MediaValue';
    definitionV2: BreakdownDefinition;
};

export type VerticalAnalysisItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'VerticalAnalysis';
    definitionV2: VerticalAnalysisDefinition;
};

export type GrowthAnalysisItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'GrowthAnalysis';
    definitionV2: GrowthAnalysisDefinition;
};

export type AdvertisingCreativeItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'AdvertisingCreative';
    definitionV2: AdvertisingCreativeDefinition;
};

export type ReportItem =
    | BreakdownItem
    | AdvertisingCreativeItem
    | GrowthAnalysisItem
    | VerticalAnalysisItem;
export type ReportItemInput =
    | BreakdownUpsertInput
    | AdvertisingCreativeUpsertInput
    | GrowthAnalysisUpsertInput
    | VerticalAnalysisUpsertInput;

//
//
// Input Types

export type BreakdownUpsertInput = Omit<ReportItemV2CreateInput, 'definitionV2'> & {
    type: 'MediaValue';
    definitionV2: BreakdownDefinitionUpsert;
    id?: string;
};

export type AdvertisingCreativeUpsertInput = Omit<ReportItemV2CreateInput, 'definitionV2'> & {
    type: 'AdvertisingCreative';
    definitionV2: CreativeReportItemUpsertInput;
    id?: string;
};

export type GrowthAnalysisUpsertInput = Omit<ReportItemV2CreateInput, 'definitionV2'> & {
    type: 'GrowthAnalysis';
    definitionV2: GrowthAnalysisUpsertDefinition;
    id?: string;
};

export type VerticalAnalysisUpsertInput = Omit<ReportItemV2CreateInput, 'definitionV2'> & {
    type: 'VerticalAnalysis';
    definitionV2: VerticalAnalysisUpsertDefinition;
    id?: string;
};

//
// draft types - drafts have everything optional
export type BreakdownDraftItem = {
    type: 'MediaValue';
    definitionV2?: BreakdownDefinitionUpsert;
    description?: string;
    name?: string;
};

export type AdvertisingCreativeDraftItem = {
    type: 'AdvertisingCreative';
    definitionV2?: CreativeReportItemUpsertInput;
    description?: string;
    name?: string;
};

export type GrowthAnalysisDraftItem = {
    type: 'GrowthAnalysis';
    definitionV2?: GrowthAnalysisUpsertDefinition;
    description?: string;
    name?: string;
};

export type VerticalAnalysisDraftItem = {
    type: 'VerticalAnalysis';
    definitionV2?: VerticalAnalysisUpsertDefinition;
    description?: string;
    name?: string;
};
export type DraftReportItem =
    | BreakdownDraftItem
    | AdvertisingCreativeDraftItem
    | GrowthAnalysisDraftItem
    | VerticalAnalysisDraftItem;

//
// Route Args
export type ReportRouteArgs = {
    id: string;
    draftReportItem?: DraftReportItem;
    triggerCreateModal?: boolean;
};
