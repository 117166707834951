import {graphql} from '~/graphql-react-query/';

export const FilterQuery = graphql(`
    query FilterQuery($input: FilterInputV2!, $filter: FilterForFilteringFiltersV2) {
        filterV2 {
            filters(input: $input, filter: $filter) {
                column
                pageInfo {
                    currentPage
                    hasNextPage
                    hasPreviousPage
                }
                items {
                    adType {
                        id
                        name
                        canonicalName
                    }
                    advertiserDomain {
                        id
                        name
                    }
                    advertiserName {
                        id
                        name
                    }
                    brand {
                        id
                        name
                        industries {
                            id
                            name
                        }
                    }
                    industry {
                        id
                        name
                    }
                    category {
                        id
                        name
                        industry {
                            id
                            name
                        }
                    }
                    campaign {
                        id
                        name
                    }
                    publication {
                        id
                        name
                    }
                    publisher {
                        id
                        name
                    }
                    region {
                        id
                        name
                    }
                    product {
                        id
                        name
                        brand {
                            id
                            name
                        }
                    }
                    mediaOwner {
                        id
                        name
                    }
                    mediaType {
                        id
                        name
                        canonicalName
                    }
                    release {
                        id
                        name
                        status
                    }
                    changeset {
                        id
                        name
                        status
                    }
                    segment {
                        id
                        name
                        color
                        emailAlert
                        teamId
                        createdAt
                        updatedAt
                        sharing {
                            status
                            usersWithAccess {
                                id
                                name
                                email
                            }
                        }
                        author {
                            id
                            name
                            email
                        }
                    }
                    location {
                        id
                        name
                    }
                    status {
                        id
                        name
                    }
                    placementType {
                        id
                        name
                    }
                }
            }
        }
    }
`);
