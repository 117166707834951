export type CreativeStatusType = 'new' | 'active' | 'inactive';

export default class CreativeStatus {
    status: CreativeStatusType;
    type = 'status' as const;

    constructor(input: {id: string; name: string}) {
        const verifiedInput = CreativeStatus.isValidStatus(input.id);
        if (!verifiedInput) throw new Error('Invalid status type');
        this.status = verifiedInput;
    }

    static isValidStatus(input: string) {
        const status = (['new', 'active', 'inactive'] as const).find((ss) => ss === input);
        if (status) return status;
    }

    get name() {
        return this.prettyStatus;
    }

    get label() {
        return `${this.prettyStatus} (${this.description})`;
    }

    get id() {
        return this.status;
    }

    // capitalizes first letter
    private get prettyStatus() {
        return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    }

    private get description() {
        switch (this.status.toLowerCase()) {
            case 'new':
                return 'first seen within 7 days';
            case 'active':
                return 'last seen within 30 days';
            case 'inactive':
                return 'not seen for 30 days';
        }
    }
}
