import {GraphqlRequest} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import {
    TrackingMutationSubmitHubspotFormArgs,
    TrackingMutationSubmitUserCreatedAccountFormArgs
} from '~/graphql-enty/graphql';

const SubmitForm = EntityStrict(() => import('~/tracking/SubmitForm.graphql'));
const SubmitUserCreatedAccountForm = EntityStrict(
    () => import('~/tracking/SubmitUserCreatedAccountForm.graphql')
);

export type TrackingApiType = {
    tracking: {
        submitHubspotForm: GraphqlRequest<boolean, TrackingMutationSubmitHubspotFormArgs>;
        submitUserCreatedAccountForm: GraphqlRequest<
            boolean,
            TrackingMutationSubmitUserCreatedAccountFormArgs
        >;
    };
};

export default {
    tracking: {
        submitHubspotForm: SubmitForm,
        submitUserCreatedAccountForm: SubmitUserCreatedAccountForm
    }
};
