import {GraphqlRequest} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import {
    OnboardingSearchInput,
    SearchV2Query,
    SearchV2QuerySearchArgs,
    UniqueIndustryGroupsSearchInput
} from '~/graphql-enty/graphql';
import {InfinitePaginationSchema} from 'bigdatr-style';
import {SearchEntityInput} from '../OnboardingSearchGrid';
import {ObjectSchema, ArraySchema} from 'react-enty';

const EntitySearch = EntityStrict(() => import('~/search/data/SearchV2.graphql'));
const OnboardingSearch = EntityStrict(() => import('~/search/data/OnboardingSearch.graphql'));
const industryGroupSearch = EntityStrict(() => import('~/search/data/IndustryGroupSearch.graphql'));

export const SearchApi = {
    searchV2: {
        search: async (payload, meta) => {
            const data = await EntitySearch(payload, meta);
            data.searchV2.id = meta.responseKey;
            return data;
        },
        onboardingSearch: async (vars: {search: OnboardingSearchInput}, meta) => {
            const response = await OnboardingSearch(vars, meta);
            response.searchV2.onboardingSearch.id = JSON.stringify([
                vars.search.query,
                vars.search.industries
            ]);
            return response;
        },
        industryGroupSearch
    }
};

export type SearchApiType = {
    searchV2: {
        search: GraphqlRequest<{searchV2: SearchV2Query}, SearchV2QuerySearchArgs>;
        onboardingSearch: GraphqlRequest<
            {searchV2: SearchV2Query},
            {search: OnboardingSearchInput}
        >;
        industryGroupSearch: GraphqlRequest<
            {searchV2: SearchV2Query},
            {search: UniqueIndustryGroupsSearchInput} | void
        >;
    };
};

export const SearchSchema = {
    searchV2: new ObjectSchema({
        onboardingSearch: new InfinitePaginationSchema<{
            items: SearchEntityInput[];
            pageInfo: {currentPage: number};
        }>('searchItemList', {
            id: (x: any) => x.id,
            shape: new ObjectSchema({
                items: new ArraySchema(new ObjectSchema({}))
            }),
            pageNumber: (x) => x.pageInfo.currentPage,
            flatten: (state, mostRecent) => {
                return {...mostRecent, items: state.flatMap((ii) => ii.items)};
            }
        })
    })
};
